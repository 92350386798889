import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./Redux/Store";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="585108892592-0tct6vbiessg3uf5mc20nt07633rnm24.apps.googleusercontent.com">
    <Provider store={Store}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={1000}
        preventDuplicate={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ fontFamily: "sans-serif", fontSize: "1rem" }}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </GoogleOAuthProvider>
);
