// React Import
import { useEffect, useState } from "react";

// MUI Import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

// Third Party Import
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Helper Import
import ColorsHelper from "../../../../Helpers/Colors";
import AppUtils from "../../../../Helpers/AppUtils";

// Redux Import
import {
  userLogin,
  findUserById,
} from "../../../../Redux/Reducers/Board/BoardActions";
import { setUser } from "../../../../Redux/Reducers/Board/BoadrdSlice";

const Authentication = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    isLogin,
    setIsLogin,
    setAuth,
    userId,
    setUserId,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      const decoded: any = jwtDecode(credentialResponse.credential);
      const email = decoded.email;
      const profile_image = decoded.picture;
      const name = decoded.name;

      const response = await userLogin({
        email,
        profile_image,
        name,
        user_type: 1,
      });

      if (response?.status === true) {
        enqueueSnackbar(response?.message, { variant: "success" });
        setUserId(response?.id);
        AppUtils.setLocalStorage(
          "ADMIN_FV",
          AppUtils.encodeStr(JSON.stringify(response))
        );
        setOpenDialog(false);
        setIsLogin(false);
        setAuth(true);
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Google Login Failed:", error);
    }
  };

  const handleGoogleLoginError: any = (error: any) => {
    console.error("Login Failed", error);
  };

  const fetchUserData = async () => {
    try {
      const res = await findUserById({ id: userId }, dispatch);
      const userData = {
        name: res?.data?.name,
        email: res?.data?.email,
        picture: res?.data?.profile_image,
        userType: res?.data?.user_type,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      dispatch(setUser(userData));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId, dispatch]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
        setIsLogin(false);
      }}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            borderRadius: "1.2rem",
          },
        },
      }}
    >
      <Box
        sx={{
          bgcolor: ColorsHelper.whiteClr,
          border: `1px solid ${ColorsHelper.cardBorder}`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Box
            component={"img"}
            src="/images/close.svg"
            sx={{ height: "2.2rem", cursor: "pointer" }}
            onClick={() => {
              setOpenDialog(false);
              setIsLogin(false);
            }}
          />
        </Box>
        <Typography
          sx={{
            color: ColorsHelper.blackClr,
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: "1.2",
            p: 1.3,
            textAlign: "center",
          }}
        >
          {isLogin ? "Login" : "Sign up"}
        </Typography>
        <Typography
          sx={{
            color: ColorsHelper.blackClr,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "1.2",
            p: { xs: 1, sm: 1.3, md: 1.3 },
            textAlign: "center",
            my: 1.5,
          }}
          dangerouslySetInnerHTML={{
            __html: isLogin
              ? "<p>Create an account or log in to get started <b>FREE!</b><p>"
              : "<p>Join us Today and get started <b>FREE!</b></p>",
          }}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            px: 4,
            mt: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GoogleLogin
            {...props}
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
            useOneTap
          />
        </Box>
        <Typography
          sx={{
            color: "#132409 !important",
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "1.2",
            p: 1.3,
            textAlign: "center",
            my: 1.5,
            cursor: "pointer",
          }}
          dangerouslySetInnerHTML={{
            __html: isLogin
              ? "Don't have an account? <u><b>Sign up</b></u>"
              : "Already have an account? <u><b>Sign in</b></u>",
          }}
          onClick={() => {
            setIsLogin(!isLogin);
          }}
        />
      </Box>
    </Dialog>
  );
};

export default Authentication;
