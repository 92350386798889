// ** Third Party Imports
import axios from "axios";

// ** Helper Imports
import AppUtils from "../AppUtils";
import UrlHelper from "../Url";
import { publicIpv4 } from "public-ip";

// Function to get the current IP address
const getIpAddress = async () => {
  try {
    const pIP = await publicIpv4();
    return pIP ? pIP : "";
  } catch (e) {
    return "";
  }
};

const apiCall: any = async (data: any) => {
  let response: any;
  const localstoreData: any = AppUtils.getLocalStorage("ADMIN_FV");

  // Get the current IP address
  const currentIp = await getIpAddress();

  const header = {
    Authorization: `Bearer ${localstoreData?.authToken ?? ""}`,
    ip: currentIp,
    ...(data.isMultipart && { "Content-Type": "multipart/form-data" }),
  };

  try {
    response = await axios({
      method: data.method,
      url: data?.isCustom
        ? `${UrlHelper.serverUrl}/customer/api/${data.url}`
        : `${UrlHelper.serverUrl}/api/${data.url}`,
      headers: header,
      data: data.data,
    });
  } catch (e: any) {
    response = e;
  }

  if (response?.status === 200 && response?.data?.status) {
    return response.data;
  } else {
    return response?.response?.data;
  }

  if (data.isLogin) return response?.data;
  else return response?.response?.data;
};

export default apiCall;
