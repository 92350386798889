import ColorsHelper from "../../../../Helpers/Colors";

const headerStyle = {
  main: {
    backgroundColor: "rgba(246, 255, 241, 1)",
    boxShadow: "none",
  },
  logoImg: {
    mr: 1,
    display: "flex",
    width: { xs: "130px", sm: "auto", md: "auto" },
    cursor: "pointer",
  },
  logoTxt: {
    mr: 2,
    display: { xs: "none", sm: "flex", md: "flex" },
    color: ColorsHelper.blackClr,
    fontWeight: 700,
    textDecoration: "none",
    cursor: "pointer",
  },
  logoTxtSM: {
    mr: 2,
    display: { xs: "flex", md: "none" },
    flexGrow: 1,
    fontWeight: 700,
    color: ColorsHelper.blackClr,
    textDecoration: "none",
  },
  logoImgSM: {
    mr: 1,
    display: { xs: "flex", md: "none" },
  },
  centerBx: {
    flexGrow: { xs: 0, md: 1 },
  },
  signup: {
    mr: { xs: 1, sm: 2, md: 2 },
    display: "flex",
    color: ColorsHelper.blackClr,
    fontWeight: 700,
    textDecoration: "none",
    textTransform: "capitalize",
    bgColor: "transparent !important",
    boxShadow: "none !important",
  },
  signIn: {
    color: "black !important",
    bgcolor: "rgba(133, 238, 68, 1) !important",
    fontWeight: 700,
    py: 1,
    px: 3,
    borderRadius: "10px",
    textDecoration: "none",
    textTransform: "capitalize",
    boxShadow: "none !important",
  },
};
export default headerStyle;
