// ** Third Party Imports
import { configureStore } from "@reduxjs/toolkit";

// ** Slice Imports
import BoadrdSlice from "./Reducers/Board/BoadrdSlice";

const Store = configureStore({
  reducer: {
    board: BoadrdSlice,
  },
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
