import ColorsHelper from "../../../../Helpers/Colors";

const Bstyle = {
  navigateArrowBox: {
    bgcolor: ColorsHelper.secondary,
    borderRadius: "8px",
    padding: 1.5,
    width: "40px",
    height: "36px",
    "&:hover": {
      bgcolor: ColorsHelper.secondary,
    },
    ml: { xs: 2, sm: 8, md: 8.2 },
    cursor: "pointer",
    mt: 3,
    mb: { xs: 0, sm: 3, md: 3 },
  },
  createMainBox: { my: 1, mx: { xs: 2, sm: 0, md: 0 } },
  noBoardTitle: {
    color: ColorsHelper.lightClr,
    fontWeight: 600,
    fontSize: "23px",
  },
  noBoardSubTitle: {
    color: ColorsHelper.lightClr,
    fontWeight: 400,
    fontSize: "16px",
    mb: 3,
    width: "21rem",
  },
  mainCreateBox: {
    backgroundColor: "white",
    border: `1px solid ${ColorsHelper.cardBorder}`,
    borderRadius: "18px",
    p: 3,
  },
  boxHeading: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: { xs: "25px", sm: "28px", md: "26px" },
    lineHeight: "1.3",
  },
  boxSubText: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: { xs: "16px", sm: "18px", md: "17px" },
    my: 2,
  },
  formLabel: {
    color: ColorsHelper.blackClr + "!important",
    fontWeight: 600,
    fontSize: "16px",
  },
  textField: {
    mt: 1,
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      height: "1rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderWidth: 2,
    },
  },
  createbtn: {
    mt: 2.5,
    bgcolor: ColorsHelper?.primary + "!important",
    color: ColorsHelper?.blackClr,
    fontSize: "17px",
    fontWeight: 600,
    px: 2,
    py: 1,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid transparent`,
  },
  custNavigateArrow: {
    bgcolor: ColorsHelper.secondary,
    borderRadius: "8px",
    padding: 1.5,
    width: "40px",
    height: "36px",
    m: 1,
    "&:hover": {
      bgcolor: ColorsHelper.secondary,
    },
    ml: { xs: "1rem", sm: "4rem", md: "4rem" },
    cursor: "pointer",
  },
  custBoardText: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: { xs: "22px", sm: "24px", md: "25px" },
  },
  custBoardSubText: {
    // color: ColorsHelper.lightClr,
    fontWeight: 500,
    fontSize: "19px",
    mb: 3,
    color: "rgba(0, 0, 0, 0.8)",
  },
  formMainBox: {
    border: `1px solid ${ColorsHelper.cardBorder}`,
    borderRadius: "18px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "white",
  },
  formBoxTitle: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: { xs: "20px", sm: "23px", md: "23px" },
    lineHeight: "1.2",
    p: { xs: "20px", sm: 2, md: 2 },
  },
  formBox: {
    // py: 1,
    p: { xs: 0, sm: 2, md: 1.5 },
    width: "80%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  copyBtn: {
    width: "1.8rem",
    height: "1.8rem",
    bgcolor: "black !important",
    mr: 0.5,
    color: "white !important",
    borderRadius: "0.6rem",
    cursor: "pointer",
  },
  copyIcon: {
    height: "1rem",
  },
  createBtn: {
    my: "20px",
    bgcolor: ColorsHelper?.primary + "!important",
    color: ColorsHelper?.blackClr,
    fontSize: "18px",
    fontWeight: 700,
    px: 2,
    py: 0.9,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid transparent`,
  },
};

export default Bstyle;
