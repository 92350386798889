// MUI Import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Customer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 5,
        bgcolor: "white",
      }}
    >
      <Container
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: { xs: "1.8rem", sm: "3rem", md: "3rem" },
            fontWeight: 700,
          }}
        >
          <p> Easy to Manage Customer Requests </p>
        </Typography>
        <Typography
          sx={{
            color: "rgba(53, 54, 54, 1)",
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
            fontWeight: 500,
            maxWidth: "600px",
          }}
        >
          <p>
            {" "}
            Efficiently manage your customer’s feedback on boards, What they
            want and schedule what to be in priority on display.{" "}
          </p>
        </Typography>

        <Box
          sx={{
            maxWidth: "1110px",
            width: "100%",
            position: "relative",
            top: { xs: 0, sm: "-50px", md: "-50px" },
          }}
        >
          <Box
            component="img"
            // src="/images/request.svg"
            src="/images/img3.png"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Customer;
