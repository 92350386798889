// React Import
import { useEffect, useState } from "react";

// MUI Import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CircularProgress from "@mui/material/CircularProgress";

// Third party Import
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { MuiColorInput } from "mui-color-input";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Helper Import
import ColorsHelper from "../../../../Helpers/Colors";

// Redux Import
import {
  getBoardById,
  fetchPostList,
  deletePost,
  editBoard,
  changeStatus,
} from "../../../../Redux/Reducers/Board/BoardActions";

// Css Import
import sugStyle from "./Suggestion.style";
import AppUtils from "../../../../Helpers/AppUtils";

interface Data {
  board_name: string;
  company_name: string;
  theme_color: string;
  public_link: string;
  embed_code: string;
}

const darkenColor = (hex: any, percent: any) => {
  let r = parseInt(hex?.substring(1, 3), 16);
  let g = parseInt(hex?.substring(3, 5), 16);
  let b = parseInt(hex?.substring(5, 7), 16);

  r = Math.floor(r * (1 - percent));
  g = Math.floor(g * (1 - percent));
  b = Math.floor(b * (1 - percent));

  const darkened = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)}`;

  return darkened;
};

const hexToRgba = (hex: any, alpha: any) => {
  const bigint = parseInt(hex?.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Suggestions = () => {
  const { index } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = localStorage.getItem("ADMIN_FV");

  const [boardData, setBoardData] = useState<any>(null);
  const [postList, setPostList] = useState<any>(null);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: 0,
    label: "All",
  });
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState("#ffffff");
  const [expandedPostId, setExpandedPostId] = useState<string | null>(null);
  const [loading, setLoading] = useState<any>(true);
  const [copiedType, setCopiedType] = useState<"link" | "iframe" | null>(null);

  const getBoardData = async () => {
    const res = await getBoardById({ id: index }, dispatch);
    if (res?.status) {
      setLoading(false);
      setBoardData(res?.data);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  }, []);

  const getPostList = async () => {
    const res = await fetchPostList(
      {
        startToken: 1,
        endToken: 20,
        board_id: index,
        status: selectedStatus.id,
      },
      dispatch
    );
    setPostList(res?.data);
  };

  useEffect(() => {
    getBoardData();
    getPostList();
  }, [index]);

  useEffect(() => {
    getPostList();
  }, [selectedStatus]);

  const buttonLabels = [
    { id: 0, label: "All", backgroundColor: "white", color: "black" },
    {
      id: 1,
      label: "In-Progress",
      backgroundColor: "rgba(240, 229, 192, 1)",
      color: "rgba(54, 130, 7, 1)",
    },
    {
      id: 2,
      label: "Not Planned",
      backgroundColor: "rgb(220 185 130)",
      color: "rgb(158 71 13)",
    },
    {
      id: 3,
      label: "Answered",
      backgroundColor: "rgba(74, 235, 154, 0.8)",
      color: "rgba(255, 255, 255, 0.8)",
    },
    {
      id: 4,
      label: "No Status",
      backgroundColor: "rgba(240, 195, 192, 1)",
      color: "rgba(130, 29, 7, 1)",
    },
  ];

  const menuButton = [
    {
      id: 1,
      label: "In-Progress",
      backgroundColor: "rgba(240, 229, 192, 1)",
      color: "rgba(54, 130, 7, 1)",
    },
    {
      id: 2,
      label: "Not Planned",
      backgroundColor: "rgb(220 185 130)",
      color: "rgb(158 71 13)",
    },
    {
      id: 3,
      label: "Answered",
      backgroundColor: "rgba(74, 235, 154, 0.8)",
      color: "rgba(255, 255, 255, 0.8)",
    },
    {
      id: 4,
      label: "No Status",
      backgroundColor: "rgba(240, 195, 192, 1)",
      color: "rgba(130, 29, 7, 1)",
    },
  ];

  const getStyles = (statusId: any) => {
    const status = menuButton.find((label) => label.id === statusId);
    return status
      ? { backgroundColor: status.backgroundColor, color: status.color }
      : { backgroundColor: "white", color: "black" };
  };

  const initialValues: Data = {
    board_name: "",
    company_name: "",
    theme_color: "",
    public_link: "",
    embed_code: "",
  };

  const schema = yup.object({
    board_name: yup.string().required("Board name is required"),
    company_name: yup.string().required("Company name is required"),
    theme_color: yup.string().required("Theme color is required"),
    public_link: yup.string().required("Public link is required"),
    embed_code: yup.string().required("Embed code is required"),
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: any) => {
      const res = await editBoard(
        {
          id: index,
          board_name: value?.board_name.trim(),
          company_name: value?.company_name.trim(),
          theme_color: value?.theme_color.trim(),
        },
        dispatch
      );

      if (res?.status === true) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(res?.message, {
          variant: "error",
        });
      }

      setOpenEdit(false);
      getBoardData();
    },
  });

  const handleCopy = (value: any, type: "link" | "iframe") => {
    navigator.clipboard.writeText(value);

    const message =
      type === "link"
        ? "Link copied successfully"
        : "iframe link copied successfully";

    enqueueSnackbar(message, {
      variant: "success",
    });

    setCopiedType(null);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
    formik.setFieldValue("theme_color", newValue);
  };

  const handleFilterChange = (event: any) => {
    const selected = buttonLabels.find(
      (label) => label.id === event.target.value
    );
    setSelectedStatus(selected);
  };

  const handleOpenEdit = () => {
    if (boardData) {
      formik.setValues({
        board_name: boardData.board_name,
        company_name: boardData.company_name,
        theme_color: boardData.theme_color,
        public_link: boardData.public_link,
      });
      formik.setFieldValue(
        "embed_code",
        `<iframe src="${boardData?.public_link}" width="100%" height="800px"></iframe>`
      );
      setValue(boardData.theme_color);
    }
    setOpenEdit(true);
  };

  return (
    <>
      <Box sx={sugStyle.main}>
        {!loading ? (
          <Box sx={sugStyle.mdBox}>
            <Box sx={sugStyle.navigateBox}>
              <Box sx={sugStyle.navigate} onClick={() => navigate("/card")}>
                <Box
                  component={"img"}
                  alt="leftArrow"
                  src="/images/leftArrow.svg"
                  sx={{ height: "14px" }}
                />
              </Box>
              <Typography sx={sugStyle.boardName}>
                <p> {boardData?.board_name || "Board Name"} </p>
              </Typography>
            </Box>
            <Box sx={sugStyle.filterBox}>
              <Box sx={sugStyle.btnLabels}>
                {buttonLabels.map((label) => (
                  <Button
                    key={label.id}
                    onClick={() => setSelectedStatus(label)}
                    sx={{
                      mr: 1,
                      bgcolor:
                        selectedStatus?.id === label.id
                          ? hexToRgba(boardData?.theme_color, 0.6)
                          : "white",
                      color:
                        selectedStatus?.id === label.id
                          ? darkenColor(boardData?.theme_color, 0.5)
                          : ColorsHelper.blackClr,
                      fontSize: "17px",
                      fontWeight: selectedStatus?.id === label.id ? 800 : 600,
                      px: 2,
                      py: 0.95,
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      border: `1px solid rgba(19, 19, 19, 0.2)`,
                      width: "auto",
                      "&:hover": {
                        bgcolor:
                          selectedStatus?.id === label.id
                            ? hexToRgba(boardData?.theme_color, 0.6)
                            : "white",
                        color:
                          selectedStatus?.id === label.id
                            ? darkenColor(boardData?.theme_color, 0.5)
                            : "#131313",
                      },
                    }}
                  >
                    <p> {label?.label} </p>
                  </Button>
                ))}
              </Box>
              <Button
                sx={{
                  ...sugStyle.settings,
                  color: "#131313",
                }}
                onClick={handleOpenEdit}
              >
                <p>Settings</p>
                <Box
                  component={"img"}
                  src="/images/setting.svg"
                  sx={{ ml: 1 }}
                />
              </Button>
            </Box>
            <Box sx={sugStyle.mainPostBox}>
              {postList && postList?.length > 0 ? (
                postList?.map((message: any, index: any) => (
                  <>
                    <Box sx={{ mt: 3, display: "flex" }} key={index}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={1.8}
                          sm={2.5}
                          md={1.5}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              display: "flex",
                              alignItems: "center",
                              justifyItems: "flex-end",
                              flexDirection: "column",
                              width: "100%",
                              borderRadius: "1.6rem",
                              height: "100%",
                              // gap: 2,
                              p: 1,
                              bgcolor: ColorsHelper.whiteClr,
                            }}
                          >
                            <Box
                              sx={{
                                mt: "5px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <KeyboardArrowUpIcon
                                sx={{ height: "2.3rem", width: "2.3rem" }}
                              />
                              <Typography sx={sugStyle.voteCount}>
                                <p> {message?.vote_count} </p>
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: ColorsHelper.blackClr,
                                fontWeight: 500,
                                fontSize: "19px",
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                mb: "8px",
                              }}
                            >
                              <p> Votes </p>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={10.2}
                          sm={9.5}
                          md={10.5}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <Box sx={sugStyle.postContentBox}>
                            <Box sx={sugStyle.titleBox}>
                              <Typography
                                sx={{
                                  ...sugStyle.title,
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {message?.title}
                              </Typography>
                              <Box sx={sugStyle.menuButtonBox}>
                                <FormControl sx={{ width: "8.5rem" }}>
                                  <Select
                                    labelId={`demo-simple-select-label-${index}`}
                                    id={`demo-simple-select-${index}`}
                                    value={message.status}
                                    defaultValue={message.status.toString()}
                                    sx={{
                                      height: "2.1rem",
                                      borderRadius: "0.5rem",
                                      bgcolor: getStyles(message.status)
                                        .backgroundColor,
                                      color: getStyles(message.status).color,
                                      fontWeight: 700,
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(0, 0, 0, 0.2)",
                                        bgcolor: getStyles(message.status)
                                          .backgroundColor,
                                        color: getStyles(message.status).color,
                                        fontWeight: 700,
                                      },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "rgba(0, 0, 0, 0.2)",
                                        },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "rgba(0, 0, 0, 0.2)",
                                        },
                                      "& .MuiSvgIcon-root": {
                                        fill: "black !important",
                                        zIndex: 99,
                                      },
                                      "& .MuiSelect-root": {
                                        bgcolor: getStyles(message.status)
                                          .backgroundColor,
                                        color: getStyles(message.status).color,
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: 700,
                                      },
                                      "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                      },
                                      "& .MuiTypography-root": {
                                        zIndex: 99,
                                        fontSize: "0.9rem",
                                      },
                                    }}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onChange={async (e) => {
                                      const selectedPostStatus = e.target.value;
                                      const res = await changeStatus(
                                        {
                                          status: parseInt(selectedPostStatus),
                                          id: message?._id,
                                        },
                                        dispatch
                                      );
                                      if (res?.status === true) {
                                        enqueueSnackbar(res?.message, {
                                          variant: "success",
                                        });

                                        getPostList();
                                      } else {
                                        enqueueSnackbar(res?.message, {
                                          variant: "error",
                                        });
                                      }
                                    }}
                                  >
                                    {menuButton.map((option) => (
                                      <MenuItem
                                        value={option.id.toString()}
                                        key={option.id}
                                      >
                                        <Typography>
                                          <p>{option.label}</p>
                                        </Typography>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                <Tooltip title="Redirect to Post">
                                  <IconButton
                                    sx={sugStyle.arrowIcon}
                                    onClick={() =>
                                      navigate(`/post/${message._id}`)
                                    }
                                  >
                                    <ArrowOutwardIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box sx={sugStyle.descriptionBox}>
                              <Typography sx={sugStyle.description}>
                                {message?.description}
                              </Typography>
                            </Box>
                            <Box sx={sugStyle.commentBox}>
                              <Typography
                                sx={sugStyle.commentCount}
                                onClick={() =>
                                  setExpandedPostId(
                                    expandedPostId === message?._id
                                      ? null
                                      : message?._id
                                  )
                                }
                              >
                                <Box
                                  component={"img"}
                                  src="/images/comment.svg"
                                  sx={{ height: "1.3rem" }}
                                />
                                <p> {message?.comment_count} </p>
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  setOpenDelete(true);
                                  setDeleteId(message?._id);
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src="/images/delete.svg"
                                  sx={{ color: "black" }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* <Collapse
                      in={expandedPostId === message?._id}
                      timeout="auto"
                      unmountOnExit={false}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={1.8} sm={2.5} md={1.5}></Grid>
                        <Grid item xs={10.2} sm={9.5} md={10.5}>
                          <Box sx={sugStyle.postContentBox}>
                            <Box
                              sx={{
                                border: `1px solid rgba(19, 19, 19, 0.2)`,
                                borderRadius: "10px",
                                m: 1,
                                p: "8px",
                              }}
                            >
                              <TextField
                                multiline
                                fullWidth
                                rows={4}
                                placeholder="Write answer here"
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "0.8rem",
                                  wordBreak: "break-word",
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "&:hover fieldset": {
                                      border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "none",
                                    },
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                fontWeight: 500,
                                fontSize: { xs: "0.7rem" },
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                py: 1,
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{
                                  mr: 2.5,
                                  color: ColorsHelper?.blackClr,
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  px: 2,
                                  py: 0.9,
                                  borderRadius: "10px",
                                  textTransform: "capitalize",
                                  border: `1px solid rgba(19, 19, 19, 0.2) !important`,
                                  boxShadow: "none !important",
                                }}
                                onClick={() => setExpandedPostId(null)}
                              >
                                <p> Cancel </p>
                              </Button>
                              <Button
                                variant="contained"
                                size="large"
                                sx={{
                                  mr: 2.5,
                                  color: darkenColor(
                                    boardData?.theme_color,
                                    0.5
                                  ),
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  px: 2,
                                  py: 0.9,
                                  borderRadius: "10px",
                                  textTransform: "capitalize",
                                  border: `1px solid rgba(19, 19, 19, 0.2) !important`,
                                  bgcolor: `${hexToRgba(
                                    boardData?.theme_color,
                                    0.6
                                  )} !important `,
                                  boxShadow: "none !important",
                                }}
                                onClick={() => handleSubmit(message?._id)}
                              >
                                <p> Submit </p>
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse> */}
                  </>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 8,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <Box
                    component={"img"}
                    src="/images/noPost.svg"
                    alt="box"
                    sx={{ height: "18rem" }}
                  /> */}
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "120px",
                      }}
                    >
                      <Box component={"img"} src="/images/box.svg" alt="box" />
                    </Box>
                    <Typography
                      align="center"
                      sx={{
                        color: ColorsHelper.lightClr,
                        fontWeight: 600,
                        fontSize: "23px",
                      }}
                    >
                      <p> There is no post created yet </p>
                    </Typography>
                    <Typography
                      align="center"
                      sx={{
                        color: ColorsHelper.lightClr,
                        fontWeight: 400,
                        fontSize: "16px",
                        mb: 3,
                        width: "21rem",
                      }}
                    >
                      <p>
                        {" "}
                        Start creating boards to enable users to share their
                        thoughts and ideas{" "}
                      </p>
                    </Typography>
                  </>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: loading ? "90vh" : "100%",
            }}
          >
            <CircularProgress color="success" />
            <Typography sx={{ my: 4 }}>Loading...</Typography>
          </Box>
        )}

        {/* xs screen */}
        <Box sx={sugStyle.xsMainBox}>
          <Box sx={sugStyle.xsNavigateBox}>
            <Box sx={sugStyle.navigate} onClick={() => navigate("/card")}>
              <Box
                component={"img"}
                alt="leftArrow"
                src="/images/leftArrow.svg"
                sx={{ height: "14px" }}
              />
            </Box>
            <Typography
              sx={{
                color: ColorsHelper.blackClr,
                fontWeight: 600,
                fontSize: "20px",
                textTransform: "capitalize",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "20ch",
                maxWidth: "100%",
              }}
            >
              <p> {boardData?.board_name || "Board Name"} </p>
            </Typography>
          </Box>
          <Box sx={sugStyle.xsFilterBox}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedStatus.id}
                defaultValue={selectedStatus.id}
                onChange={handleFilterChange}
                sx={sugStyle.xsButtonLabelSelect}
                inputProps={{ "aria-label": "Without label" }}
                fullWidth
              >
                {buttonLabels.map((key: any) => (
                  <MenuItem value={key.id} key={key.id}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 600,
                        color: "#131313",
                      }}
                    >
                      <p>{key.label}</p>
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button sx={sugStyle.xsSettings} fullWidth onClick={handleOpenEdit}>
              <p>Settings</p>
              <Box component={"img"} src="/images/setting.svg" sx={{ ml: 1 }} />
            </Button>
          </Box>
          <Box sx={sugStyle.xsMainPostBox}>
            {postList && postList?.length > 0 ? (
              postList?.map((message: any, index: any) => (
                <>
                  <Box sx={sugStyle.xsPostListBox} key={index}>
                    <Box sx={sugStyle.xsPostTitleBox}>
                      <Typography sx={sugStyle.title}>
                        <p
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "13ch",
                            fontSize: "20px",
                          }}
                        >
                          {message?.title}
                        </p>
                      </Typography>
                      <Box sx={sugStyle.xsMenuBox}>
                        <FormControl>
                          <Select
                            labelId={`demo-simple-select-label-${index}`}
                            id={`demo-simple-select-${index}`}
                            value={message?.status}
                            defaultValue={message.status.toString()}
                            sx={{
                              height: "2.5rem",
                              borderRadius: "0.8rem",
                              bgcolor: getStyles(message?.status)
                                .backgroundColor,
                              color: getStyles(message?.status).color,
                              fontWeight: 700,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.2)",
                                bgcolor: getStyles(message?.status)
                                  .backgroundColor,
                                color: getStyles(message?.status).color,
                                fontWeight: 700,
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "rgba(0, 0, 0, 0.2)",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.2)",
                              },
                              "& .MuiSvgIcon-root": {
                                fill: "black !important",
                                zIndex: 99,
                              },
                              "& .MuiSelect-root": {
                                bgcolor: getStyles(message.status)
                                  .backgroundColor,
                                color: getStyles(message.status).color,
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 700,
                              },
                              "& .MuiSelect-select": {
                                display: "flex",
                                alignItems: "center",
                              },
                              "& .MuiTypography-root": {
                                zIndex: 99,
                              },
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={async (e) => {
                              const selectedPostStatus = e.target.value;
                              const res = await changeStatus(
                                {
                                  status: parseInt(selectedPostStatus),
                                  id: message?._id,
                                },
                                dispatch
                              );
                              if (res?.status === true) {
                                enqueueSnackbar(res?.message, {
                                  variant: "success",
                                });

                                getPostList();
                              } else {
                                enqueueSnackbar(res?.message, {
                                  variant: "error",
                                });
                              }
                            }}
                          >
                            {menuButton?.map((option) => (
                              <MenuItem
                                value={option.id.toString()}
                                key={option.id}
                              >
                                <Typography>
                                  <p>{option.label}</p>
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box sx={sugStyle.xsDescriptionBox}>
                      <Typography sx={sugStyle.xsDescription}>
                        <p> {message?.description} </p>
                      </Typography>
                    </Box>
                    <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={sugStyle.xsVoteCount}
                      >
                        <p> {message?.vote_count}</p>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            alignItems: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          <p> Votes </p>
                        </span>
                      </Button>
                    </Box>
                    <Box sx={sugStyle.xsCommentCount}>
                      <Typography
                        sx={{ display: "flex" }}
                        onClick={() =>
                          setExpandedPostId(
                            expandedPostId === message?._id
                              ? null
                              : message?._id
                          )
                        }
                      >
                        <p> {message?.comment_count} Comments </p>
                      </Typography>

                      <Box>
                        <IconButton
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteId(message?._id);
                          }}
                        >
                          <Box
                            component={"img"}
                            src="/images/delete.svg"
                            sx={{ color: "black", mr: 1 }}
                          />
                        </IconButton>
                        <Tooltip title="Redirect to Post">
                          <IconButton
                            sx={sugStyle.arrowIcon}
                            onClick={() => navigate(`/post/${message._id}`)}
                          >
                            <ArrowOutwardIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Collapse
                    in={expandedPostId === message?._id}
                    timeout="auto"
                    unmountOnExit={false}
                    sx={{ mb: 2 }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Box sx={sugStyle.postContentBox}>
                          <Box
                            sx={{
                              border: `1px solid rgba(19, 19, 19, 0.2)`,
                              borderRadius: "10px",
                              p: "6px",
                            }}
                          >
                            <TextField
                              multiline
                              fullWidth
                              rows={4}
                              placeholder="Write answer here"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                              sx={{
                                fontWeight: "500",
                                fontSize: "0.8rem",
                                wordBreak: "break-word",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "&:hover fieldset": {
                                    border: "none",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none",
                                  },
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              cursor: "pointer",
                              fontWeight: 500,
                              fontSize: { xs: "0.7rem" },
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              py: 2,
                            }}
                          >
                            <Button
                              variant="outlined"
                              fullWidth
                              sx={{
                                mr: 2.5,
                                color: ColorsHelper?.blackClr,
                                fontSize: "16px",
                                fontWeight: 600,
                                px: 2,
                                py: 0.9,
                                borderRadius: "10px",
                                textTransform: "capitalize",
                                border: `1px solid rgba(19, 19, 19, 0.2) !important`,
                                boxShadow: "none !important",
                              }}
                              onClick={() => setExpandedPostId(null)}
                            >
                              <p> Cancel </p>
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                color: darkenColor(boardData?.theme_color, 0.5),
                                fontSize: "16px",
                                fontWeight: 700,
                                px: 2,
                                py: 0.9,
                                borderRadius: "10px",
                                textTransform: "capitalize",
                                border: `1px solid rgba(19, 19, 19, 0.2) !important`,
                                bgcolor: `${hexToRgba(
                                  boardData?.theme_color,
                                  0.6
                                )} !important `,
                                boxShadow: "none !important",
                              }}
                              onClick={() => handleSubmit(message?._id)}
                            >
                              <p> Submit </p>
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Collapse> */}
                </>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 8,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* <Box
                  component={"img"}
                  src="/images/noPost.svg"
                  alt="box"
                  sx={{ height: "18rem" }}
                /> */}
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "120px",
                    }}
                  >
                    <Box component={"img"} src="/images/box.svg" alt="box" />
                  </Box>
                  <Typography
                    align="center"
                    sx={{
                      color: ColorsHelper.lightClr,
                      fontWeight: 600,
                      fontSize: "23px",
                    }}
                  >
                    <p> There is no post created yet </p>
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      color: ColorsHelper.lightClr,
                      fontWeight: 400,
                      fontSize: "16px",
                      mb: 3,
                      width: "21rem",
                    }}
                  >
                    <p>
                      {" "}
                      Start creating boards to enable users to share their
                      thoughts and ideas{" "}
                    </p>
                  </Typography>
                </>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        maxWidth="sm"
        sx={sugStyle.editDialog}
      >
        <Box sx={sugStyle.editDialogBox}>
          <Typography sx={sugStyle.editTitle}>
            <p>Edit your board </p>
          </Typography>

          <Divider sx={{ width: "100%" }} />

          <Box sx={sugStyle.editFormBox}>
            <FormControl
              fullWidth
              error={
                formik.touched.board_name && Boolean(formik.errors.board_name)
              }
              sx={{ mt: 1 }}
            >
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={sugStyle.formLabel}
              >
                <p>Board Name</p>
              </FormLabel>
              <OutlinedInput
                placeholder="Enter board Name"
                fullWidth
                id="board_name"
                value={formik?.values?.board_name}
                onChange={(e: any) => {
                  if (!AppUtils.checkWhiteSpace(e.target.value)) {
                    formik.setFieldValue("board_name", e.target.value);
                  }
                }}
                sx={{
                  ...sugStyle.boardNameField,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                    borderWidth: 2,
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.7rem",
                  },
                }}
              />
              {formik.touched.board_name && formik.errors.board_name ? (
                <FormHelperText>{formik.errors.board_name}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl
              fullWidth
              error={
                formik.touched.company_name &&
                Boolean(formik.errors.company_name)
              }
              sx={{ mt: 1 }}
            >
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={sugStyle.formLabel}
              >
                <p>Company Name</p>
              </FormLabel>
              <OutlinedInput
                placeholder="Enter Your Company Name"
                fullWidth
                id="company_name"
                value={formik.values.company_name}
                onChange={(e: any) => {
                  if (!AppUtils.checkWhiteSpace(e.target.value)) {
                    formik.setFieldValue("company_name", e.target.value);
                  }
                }}
                sx={{
                  ...sugStyle.boardNameField,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                    borderWidth: 2,
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.7rem",
                  },
                }}
              />
              {formik.touched.company_name && formik.errors.company_name ? (
                <FormHelperText sx={{ ml: 0 }}>
                  {formik.errors.company_name}
                </FormHelperText>
              ) : null}
            </FormControl>

            <FormControl
              fullWidth
              error={
                formik.touched.theme_color && Boolean(formik.errors.theme_color)
              }
              sx={{ mt: 1 }}
            >
              <FormLabel sx={sugStyle.formLabel}>
                <p>Select theme color</p>
              </FormLabel>
              <MuiColorInput
                format="hex"
                value={value}
                onChange={handleChange}
                sx={{
                  ...sugStyle.colorInput,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                    borderWidth: 2,
                  },
                }}
              />
            </FormControl>

            <FormControl
              fullWidth
              error={
                formik.touched.public_link && Boolean(formik.errors.public_link)
              }
              sx={{ mt: 1 }}
            >
              <FormLabel sx={sugStyle.formLabel}>
                <p>Public Link</p>
              </FormLabel>
              <OutlinedInput
                placeholder="Enter Public Link"
                fullWidth
                id="public_link"
                name="public_link"
                disabled
                value={formik.values.public_link}
                onChange={formik.handleChange}
                endAdornment={
                  <Box
                    component={"img"}
                    onClick={() => {
                      handleCopy(formik.values.public_link, "link");
                    }}
                    sx={sugStyle.copyButton}
                    src="/images/copy.png"
                  />
                }
                sx={{
                  ...sugStyle.boardNameField,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                    borderWidth: 2,
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.7rem",
                  },
                }}
              />
              {formik.touched.public_link && formik.errors.public_link && (
                <FormHelperText>{formik.errors.public_link}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={
                formik.touched.embed_code && Boolean(formik.errors.embed_code)
              }
              sx={{ mt: 1 }}
            >
              <FormLabel sx={sugStyle.formLabel}>
                <p>Embed Code</p>
              </FormLabel>
              <OutlinedInput
                placeholder="Enter Embed Code"
                fullWidth
                id="embed_code"
                disabled
                name="embed_code"
                value={formik.values.embed_code}
                onChange={formik.handleChange}
                endAdornment={
                  <Box
                    component={"img"}
                    onClick={() => {
                      handleCopy(formik.values.embed_code, "iframe");
                    }}
                    sx={sugStyle.copyButton}
                    src="/images/copy.png"
                  />
                }
                sx={{
                  ...sugStyle.boardNameField,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: " black !important",
                    borderWidth: 2,
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.7rem",
                  },
                }}
              />
              {formik.touched.embed_code && formik.errors.embed_code && (
                <FormHelperText>{formik.errors.embed_code}</FormHelperText>
              )}
            </FormControl>

            <Button
              fullWidth
              sx={{
                ...sugStyle.saveButton,
                bgcolor:
                  hexToRgba(value || boardData?.theme_color, 0.6) +
                  "!important",
                color: darkenColor(boardData?.theme_color, 0.5) + "!important",
              }}
              onClick={() => formik.handleSubmit()}
            >
              <p>Save</p>
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setDeleteId(null);
        }}
        maxWidth="sm"
        sx={sugStyle.editDialog}
      >
        <Box
          sx={{
            ...sugStyle.editDialogBox,
            p: "25px 15px",
          }}
        >
          <Typography sx={sugStyle.deleteText}>
            <p> Are you sure want to delete this Post? </p>
          </Typography>
          <Box sx={sugStyle.deleteBtnBox}>
            <Button
              variant="outlined"
              sx={sugStyle.cancleBtn}
              onClick={() => {
                setOpenDelete(false);
                setDeleteId(null);
              }}
            >
              <p>Cancel</p>
            </Button>
            <Button
              variant="contained"
              sx={sugStyle.deleteBtn}
              onClick={async () => {
                const res = await deletePost({ id: deleteId }, dispatch);
                if (res && res.status === true) {
                  enqueueSnackbar(res?.message, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(res?.message, {
                    variant: "error",
                  });
                }
                setOpenDelete(false);
                getPostList();
                setDeleteId(null);
              }}
            >
              <p> Delete</p>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Suggestions;
