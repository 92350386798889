// MUI Import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

// Third Party Import
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const GetStart = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const storedUser: any = localStorage.getItem("ADMIN_FV");
    if (storedUser) {
      navigate("/card");
    } else {
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 5,
        bgcolor: "white",
      }}
    >
      <Container
        sx={{
          my: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", sm: "100%", md: "80%" },
            justifyContent: "flex-end",
          }}
        >
          <Box component={"img"} src="/images/icon.svg" />
        </Box>
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: { xs: "2rem", sm: "3rem", md: "3rem" },
            fontWeight: 700,
            display: "flex",
          }}
        >
          <p>
            {" "}
            Get Started with{" "}
            <span
              style={{
                color: "rgba(54, 130, 7, 1)",
              }}
            >
              FeedVault
            </span>{" "}
            Today!
          </p>
        </Typography>
        <Typography
          sx={{
            color: "#353636",
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
            fontWeight: 500,
            maxWidth: "550px",
          }}
        >
          <p>
            {" "}
            Create custom feedback boards to enhance user engagement & gather
            valuable feedbacks.
          </p>
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            gap: 1,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)", mr: 1 }} />
              <Typography
                sx={{
                  color: "rgba(19, 36, 9, 1)",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                <p> Free Forever! </p>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)", mr: 1 }} />
              <Typography
                sx={{
                  color: "rgba(19, 36, 9, 1)",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                <p> Quick & Easy Setup </p>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)", mr: 1 }} />
              <Typography
                sx={{
                  color: "rgba(19, 36, 9, 1)",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                <p> Get FeedVault for Free </p>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{
            mt: 5,
            bgcolor: "#85EE44 !important",
            color: "black",
            fontWeight: 700,
            borderRadius: "10px",
            textTransform: "none",
            boxShadow: "none !important",
          }}
          onClick={handleClick}
        >
          <p>Get FeedVault Free</p>
        </Button>
      </Container>
    </Box>
  );
};

export default GetStart;
