// React Import
import { useEffect, useState } from "react";

// MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TelegramIcon from "@mui/icons-material/Telegram";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CircularProgress from "@mui/material/CircularProgress";

// Third Party Import
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Redux Import
import {
  fetchBoardData,
  deleteBoard,
} from "../../../../Redux/Reducers/Board/BoardActions";

// Css Import
import Cstyle from "./card.styles";

const Card = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [boards, setBoards] = useState<any>(null);
  const [isDelete, setIsDelete] = useState<{ open: boolean; id: string }>({
    open: false,
    id: "",
  });
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    const user = localStorage.getItem("ADMIN_FV");

    if (!user) {
      navigate("/");
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  }, []);

  const fetchBlogData = async () => {
    const res = await fetchBoardData(
      {
        startToken: 1,
        endToken: 10,
        search: "",
      },
      dispatch
    );

    if (res?.status) {
      setLoading(false);
      setBoards(res?.data);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  return (
    <>
      {!loading ? (
        <Box
          sx={{
            p: { xs: "24px", sm: 5, md: 5 },
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box sx={Cstyle.createBox}>
            <Button sx={Cstyle.createBtn} onClick={() => navigate("/home")}>
              <AddOutlinedIcon
                sx={{ display: { xs: "flex", sm: "none", md: "none" }, mr: 1 }}
              />{" "}
              <p>Create Board</p>
            </Button>
          </Box>

          <Box sx={{ width: { xs: "100%", sm: "85%", md: "75%" } }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {boards && boards.length > 0 ? (
                boards.map((board: any, index: any) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #00000033",
                        borderRadius: 2,
                        height: "120px",
                        position: "relative",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center !important",
                        justifyContent: "center !important",
                        transition: "all 0.5s ease-in-out",
                        "&:hover .title": {
                          position: "absolute",
                          transform: "translate(-80%, -120%)",
                          fontSize: "16px",
                        },
                        "&:hover .color-box": {
                          bottom: "-10rem",
                        },
                        "&:hover .btns": {
                          bottom: 0,
                        },
                      }}
                    >
                      <Typography
                        className="title"
                        sx={{
                          ...Cstyle.boardTitle,
                          transition: "all 0.5s ease-in-out",
                          // position: openDelete ? "relative" : "absolute",
                          // transform: openDelete
                          //   ? "none"
                          //   : "translate(-80%, -120%)",
                        }}
                      >
                        {board?.board_name}
                      </Typography>
                      <Box
                        className="color-box"
                        sx={{
                          position: "absolute",
                          p: 2,
                          bottom:
                            isDelete?.open && isDelete?.id === board?._id
                              ? "-10rem"
                              : "-1.5rem",
                          right: "-1.8rem",
                          height: "60px",
                          width: "178px",
                          rotate: "163deg",
                          borderRadius: "1rem",
                          transition: "all 0.5s ease-in-out",
                          bgcolor: board?.theme_color,
                        }}
                      />
                      <Box
                        className="btns"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          bottom:
                            isDelete?.open && isDelete?.id === board?._id
                              ? 0
                              : "-2rem",
                          right: 0,
                          transition: "all 0.5s ease-in-out",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            navigate(`/suggestion/${board?._id}`);
                          }}
                        >
                          <RemoveRedEyeIcon sx={{ color: "black" }} />
                        </IconButton>

                        <IconButton
                          onClick={(e) => {
                            window.open(board?.public_link, "_blank");
                          }}
                        >
                          <TelegramIcon sx={{ color: "black" }} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            setIsDelete({ open: true, id: board?._id })
                          }
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Box sx={Cstyle.noBoardBox}>
                  <Box
                    component={"img"}
                    alt="error-illustration"
                    src="/images/noBoard.svg"
                    sx={{ height: "23rem" }}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: loading ? "90vh" : "100%",
          }}
        >
          <CircularProgress color="success" />
          <Typography sx={{ my: 4 }}>Loading...</Typography>
        </Box>
      )}

      <Dialog
        open={isDelete?.open}
        onClose={() => setIsDelete({ open: false, id: "" })}
        maxWidth="sm"
        sx={Cstyle.dialog}
      >
        <Box sx={Cstyle.dialogMainBox}>
          <Typography sx={Cstyle.deleteText}>
            <p> Are you sure want to delete this Board? </p>
          </Typography>
          <Box sx={Cstyle.buttonBox}>
            <Button
              type="button"
              variant="outlined"
              sx={Cstyle.cancelBtn}
              onClick={() => setIsDelete({ open: false, id: "" })}
            >
              <p>Cancel</p>
            </Button>
            <Button
              variant="contained"
              type="button"
              sx={Cstyle.deleteBtn}
              onClick={async () => {
                const res = await deleteBoard({ id: isDelete?.id }, dispatch);
                if (res?.status === true) {
                  enqueueSnackbar(res?.message, {
                    variant: "success",
                  });
                  fetchBlogData();
                  setIsDelete({ open: false, id: "" });
                } else {
                  enqueueSnackbar(res?.message, {
                    variant: "error",
                  });
                }
              }}
            >
              <p>Delete</p>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Card;
