import ColorsHelper from "../../../../Helpers/Colors";

const sugStyle = {
  main: {
    height: "calc(100vh - 4rem)",
    width: "100%",
  },
  mdBox: {
    p: "20px 62px",
    width: "100%",
    display: { xs: "none", sm: "flex", md: "flex" },
    flexDirection: "column",
    // background: `linear-gradient(135deg, ${hexToRgba(
    //   boardData?.theme_color,
    //   0.6
    // )} 30%, ${boardData?.theme_color} 70%)`,}
  },
  navigateBox: {
    display: "flex",
    alignItems: "center",
    gap: 3,
    mb: 3,
  },
  navigate: {
    bgcolor: ColorsHelper.secondary,
    borderRadius: "8px",
    padding: 1.5,
    width: "40px",
    height: "36px",
    "&:hover": {
      bgcolor: ColorsHelper.secondary,
    },
    // ml: 1,
    cursor: "pointer",
  },
  boardName: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "28px",
    textTransform: "capitalize",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    mt: "0.5rem",
  },
  btnLabels: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: { sm: "70%", md: "auto" },
    scrollbarWidth: "none",
  },
  settings: {
    fontSize: "18px",
    fontWeight: 600,
    px: 2,
    py: 1,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid rgba(19, 19, 19, 0.2)`,
    width: { xs: "100%", sm: "auto" },
    bgcolor: "transparent",
  },
  mainPostBox: {
    height: "calc(100vh - 15.8rem)",
    overflow: "auto",
    scrollbarWidth: "none",
  },
  voteBox: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
    width: "100%",
    borderRadius: "18px",
    height: "100%",
    gap: 2,
    p: 1,
    bgcolor: ColorsHelper.whiteClr,
  },
  voteCount: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "30px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  voteText: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "20px",
  },
  postContentBox: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    display: "flex",
    width: "100%",
    borderRadius: "18px",
    p: "10px 22px",
    flexDirection: "column",
    bgcolor: ColorsHelper.whiteClr,
  },
  titleBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 3,
  },
  title: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "21px",
  },
  menuButtonBox: {
    width: 190,
    borderRadius: "0.8rem",
    display: "flex",
    gap: 1,
  },
  arrowIcon: {
    borderRadius: "8px !important",
    height: "2.1rem",
    // bgcolor: boardData?.theme_color + "!important",
    bgcolor: "#85EE44 !important",
    // border: `1px solid rgba(19, 19, 19, 0.2)`,
    color: ColorsHelper?.blackClr,
    width: "2.2rem",
  },
  descriptionBox: {
    display: "flex",
    width: "70%",
    flexDirection: "column",
    py: "10px",
  },
  description: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "15.5px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  commentBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commentCount: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "14px",
  },
  noPostBox: { display: "flex", justifyContent: "center", mt: 6 },

  //   xs Screen Css

  xsMainBox: {
    px: 2,
    width: "100%",
    display: { xs: "flex", sm: "none", md: "none" },
    flexDirection: "column",
    height: "calc(100vh - 4rem)",
  },
  xsNavigateBox: {
    display: "flex",
    alignItems: "center",
    my: 1,
    gap: 2,
    mb: 3,
  },
  xsFilterBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 1,
    gap: 3,
  },
  xsButtonLabelSelect: {
    height: "2.8rem",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "& .MuiSvgIcon-root ": {
      fill: "black !important",
    },
    borderRadius: "0.5rem",
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  },
  xsSettings: {
    fontSize: "17px",
    fontWeight: 600,
    px: 2,
    py: 1,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid rgba(19, 19, 19, 0.2)`,
    width: "100%",
    bgcolor: "transparent",
    justifyContent: "space-between",
    color: "#131313",
  },
  xsMainPostBox: {
    height: "calc(100vh - 10rem)",
    overflow: "auto",
    scrollbarWidth: "none",
    mt: 2,
  },
  xsPostListBox: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    mb: 2,
    borderRadius: "0.8rem",
    bgcolor: ColorsHelper.whiteClr,
  },
  xsPostTitleBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  xsMenuBox: {
    width: "8.2rem",
    borderRadius: "0.8rem",
    display: "flex",
    gap: 2,
  },
  xsDescriptionBox: {
    mt: 2,
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  xsDescription: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "14px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  xsVoteCount: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "1.4rem",
    borderRadius: "0.7rem",
    gap: 1,
  },
  xsCommentCount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  //   Edit Dialog

  editDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "400px",
        borderRadius: "1rem",
      },
    },
  },
  editDialogBox: {
    bgColor: ColorsHelper.whiteClr,
    border: `1px solid ${ColorsHelper.cardBorder}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  editTitle: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "1.2",
    p: "15px",
  },
  editFormBox: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  formLabel: {
    color: ColorsHelper.blackClr + "!important",
    fontWeight: 600,
    fontSize: "15px",
    my: 0.8,
  },
  boardNameField: {
    borderRadius: "10px",
  },
  colorInput: {
    "& .MuiOutlinedInput-input": {
      height: "0.7rem",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  copyButton: {
    width: "1.8rem",
    height: "1.7rem",
    bgcolor: "black !important",
    mr: 0.5,
    color: "white !important",
    borderRadius: "0.6rem",
    cursor: "pointer",
  },
  copyIcon: {
    height: "1.1rem",
    color: "white !important",
  },
  saveButton: {
    my: 2,
    color: ColorsHelper?.blackClr,
    fontSize: "17px",
    fontWeight: 700,
    px: 2,
    py: 0.9,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid black`,
    boxShadow: "none !important",
  },

  //   Delete Dialog

  deleteDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "380px",
        borderRadius: "1.2rem",
      },
    },
  },
  deleteText: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "1.2",
    p: 1.3,
    textAlign: "center",
  },
  deleteBtnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    px: { xs: 0, sm: 4, md: 4 },
    mt: 2,
  },
  cancleBtn: {
    bgcolor: "white !important",
    color: "black !important",
    textTransform: "capitalize",
    fontSize: "1.1rem",
    py: 1,
    borderRadius: "0.7rem",
    border: "1px solid rgba(19, 19, 19, 0.2) !important",
    width: "8rem",
    boxShadow: "none !important",
  },
  deleteBtn: {
    bgcolor: "rgba(240, 195, 192, 1) !important",
    color: "rgba(130, 29, 7, 1) !important",
    textTransform: "capitalize",
    fontSize: "1.1rem",
    py: 1,
    borderRadius: "0.7rem",
    width: "8rem",
    borderColor: "rgba(130, 29, 7, 1) !important",
    boxShadow: "none !important",
  },
};
export default sugStyle;
