// React Imports
import { useEffect, useState } from "react";

// MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress, useTheme } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// Third Party Import
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

// Redux Import
import {
  getPostById,
  addComment,
  addVote,
  editComment,
} from "../../../Redux/Reducers/Board/BoardActions";

// Css Import
import comStyle from "./Comment.style";

const MAX_TEXT_LENGTH = 100;
const XS_MAX_TEXT_LENGTH = 80;

const Comments = () => {
  const { id } = useParams<any>();

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [postData, setPostData] = useState<any>();
  const [chats, setChats] = useState<any>([]);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<any>(null);
  const [color, setColor] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [expandedComments, setExpandedComments] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState<any>(true);

  const userData: any = JSON.parse(localStorage.getItem("comUser") as any);

  const getPostData = async () => {
    const res = await getPostById({ id: id }, dispatch);

    if (res?.status) {
      setPostData(res?.data);
      setChats(res?.data?.comments);
      setCompanyName(res?.data?.board_id?.company_name);
      setColor(res?.data?.board_id?.theme_color);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPostData();
  }, [id]);

  const handleSubmit = async () => {
    if (!comment.trim()) {
      setError("Comment cannot be empty");
      return;
    }
    setError(null);
    if (editingCommentId) {
      const res: any = await editComment(
        { post_id: id, comment_id: editingCommentId, text: comment },
        dispatch
      );
      if (res?.status === true) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        setChats(res?.data?.comments);
        setEditingCommentId(null);
      } else {
        enqueueSnackbar(res?.message, {
          variant: "error",
        });
      }
    } else {
      const res: any = await addComment(
        { post_id: id, text: comment, comment_by: userData?.email },
        dispatch
      );
      if (res?.status === true) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        getPostData();
        setChats(res?.data?.comments);
      } else {
        enqueueSnackbar(res?.message, {
          variant: "error",
        });
      }
    }
    setComment("");
  };

  const handleEditClick = (comment: any) => {
    setComment(comment.text);
    setEditingCommentId(comment._id);
  };

  const formatDate = (dateString: any) => {
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const buttonLabels = [
    { id: 0, label: "All" },
    {
      id: 1,
      label: "In-Progress",
      backgroundColor: "rgba(240, 229, 192, 1)",
      color: "rgba(71, 78, 2, 1)",
    },
    {
      id: 2,
      label: "Not Planned",
      backgroundColor: "rgb(220 185 130)",
      color: "rgb(158 71 13)",
    },
    {
      id: 3,
      label: "Answered",
      backgroundColor: "rgba(74, 235, 154, 0.8)",
      color: "rgba(255, 255, 255, 0.8)",
    },
    {
      id: 4,
      label: "No Status",
      backgroundColor: "rgba(240, 195, 192, 1)",
      color: "rgba(130, 29, 7, 1)",
    },
  ];

  const status = postData?.status;
  const matchedLabel = buttonLabels.find((label) => label.id === status);

  function lightenHex(hex: any, percent: any) {
    const num = parseInt(hex?.replace("#", ""), 16),
      amt = Math.round(2.55 * percent * 100),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }

  const toggleReadMore = (index: string) => {
    setExpandedComments((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleVoteClick = async (id: any) => {
    const storedMessageIds: any =
      JSON.parse(localStorage.getItem("votedMessageIds") as any) || [];

    if (!storedMessageIds.includes(id)) {
      const res = await addVote({ post_id: id }, dispatch);

      if (res?.status === true) {
        storedMessageIds.push(id);

        localStorage.setItem(
          "votedMessageIds",
          JSON.stringify(storedMessageIds)
        );

        getPostData();
      }
    } else {
      enqueueSnackbar("You have already voted for this post.", {
        variant: "info",
      });
    }
  };

  const votedMessageIds =
    JSON.parse(localStorage.getItem("votedMessageIds") as any) || [];

  const hasUserVoted = votedMessageIds.includes(postData?._id);

  return !loading ? (
    <>
      <Box sx={comStyle.mainBox}>
        {!isXs ? (
          <Box sx={comStyle.fScreenMainBox}>
            <Box sx={comStyle.fscreenSubBox}>
              <Typography sx={comStyle.companyName}>
                {companyName ? companyName.replace(/-/g, " ") : "My Circle"}
              </Typography>
              <Typography
                onClick={() => window.open("https://feedmire.com/", "_blank")}
                sx={{ cursor: "pointer" }}
              >
                powered by <u style={{ color: "#112C00" }}>FeedVault</u>
              </Typography>
            </Box>

            <Box sx={comStyle.contentBox}>
              <Box
                sx={comStyle.backArrowBox}
                onClick={() =>
                  window.location.replace(`${postData?.board_id?.public_link}`)
                }
              >
                <Box
                  component={"img"}
                  alt="leftArrow"
                  src="/images/leftArrow.svg"
                  sx={{ height: "14px" }}
                />
              </Box>
              <Box sx={comStyle.dataBox}>
                {matchedLabel && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: `${matchedLabel.backgroundColor} !important`,
                      color: `${matchedLabel.color} !important`,
                      ...comStyle.matchLabel,
                      width: "max-content",
                    }}
                  >
                    <p>{matchedLabel.label}</p>
                  </Button>
                )}
                <Box sx={comStyle.postDataBox}>
                  <Box sx={comStyle.postDataSubBox}>
                    <Box sx={comStyle.mainPostData}>
                      <Typography sx={comStyle.postDataTitle}>
                        <p> {postData?.title} </p>
                      </Typography>
                      <Typography sx={comStyle.postDataDescription}>
                        <p> {postData?.description} </p>
                      </Typography>
                      <Box sx={comStyle.detailsBox}>
                        {userData?.profile_image ? (
                          <Box
                            component={"img"}
                            src={userData?.profile_image}
                            sx={{ height: "1.7rem", borderRadius: "50%" }}
                          />
                        ) : (
                          <AccountCircleIcon />
                        )}
                        <Typography sx={comStyle.postDataText}>
                          <p> {postData?.post_by} </p>
                        </Typography>
                        |
                        <Typography sx={comStyle.postDataText}>
                          <p> {formatDate(postData?.createdAt)} </p>
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={comStyle.voteBox}>
                      <Box
                        sx={{
                          ...comStyle.voteSubBox,
                          backgroundColor: hasUserVoted
                            ? `${lightenHex(color, 0.3)} !important`
                            : "white",
                        }}
                        // onClick={async () => {
                        //   const res = await addVote({ post_id: id }, dispatch);
                        //   if (res?.status === true) {
                        //     getPostData();
                        //   }
                        // }}
                        onClick={() => handleVoteClick(id)}
                      >
                        <Typography sx={comStyle.voteCount}>
                          {postData?.votes.length}
                        </Typography>
                        <Typography sx={comStyle.voteText}>
                          <p>Votes</p>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={comStyle.commentSection}>
                    <Box sx={comStyle.commentBox}>
                      <Typography sx={comStyle.commentLength}>
                        <p> {postData?.comments.length} Comments </p>
                      </Typography>

                      <Box sx={comStyle.writeCommentBox}>
                        {userData?.profile_image ? (
                          <Box
                            component={"img"}
                            src={userData?.profile_image}
                            sx={{
                              height: "2.5rem",
                              borderRadius: "50%",
                              mt: 1,
                              mr: 2,
                            }}
                          />
                        ) : (
                          <AccountCircleIcon sx={comStyle.userIcon} />
                        )}
                        <Box sx={comStyle.fieldBox}>
                          <OutlinedInput
                            placeholder="Leave a comment"
                            fullWidth
                            multiline
                            rows={3}
                            value={comment}
                            onChange={(e: any) => setComment(e.target.value)}
                            sx={{
                              ...comStyle.textField,
                              borderColor: error ? "red" : "inherit",
                            }}
                            error={!!error}
                          />
                          {error && (
                            <Typography sx={{ color: "red" }}>
                              {error}
                            </Typography>
                          )}
                          <Button
                            fullWidth
                            sx={{
                              bgcolor: lightenHex(color, 0.3) + "!important",
                              ...comStyle.submitBtn,
                            }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Box>

                      <Box sx={comStyle.replyBox}>
                        {chats &&
                          chats.map((item: any, index: any) => {
                            const isExpanded = expandedComments[index] || false;
                            const isLongText =
                              item.text.length > MAX_TEXT_LENGTH;
                            return (
                              <Box
                                sx={{
                                  ...comStyle.chatsBox,
                                  bgcolor:
                                    item?.user_type === 1
                                      ? "rgba(243, 243, 243, 1)"
                                      : "white",
                                }}
                                key={index}
                              >
                                <Box sx={comStyle.chatsSubBox}>
                                  <Typography sx={{ fontSize: "1.1rem" }}>
                                    <p>
                                      {isExpanded || !isLongText
                                        ? item.text
                                        : item.text.slice(0, MAX_TEXT_LENGTH)}
                                      {isLongText && (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                          }}
                                          onClick={() =>
                                            toggleReadMore(index.toString())
                                          }
                                        >
                                          {isExpanded
                                            ? "...Read Less"
                                            : "...Read More"}
                                        </span>
                                      )}
                                    </p>
                                  </Typography>
                                  <Box sx={comStyle.chatUserBox}>
                                    <AccountCircleIcon />
                                    <Typography sx={comStyle.dataText}>
                                      <p> {item.comment_by} </p>
                                    </Typography>
                                    |
                                    <Typography sx={comStyle.dataText}>
                                      <p> {formatDate(item.time)} </p>
                                    </Typography>
                                    {/* |
                                <Typography
                                  sx={comStyle.actionText}
                                  onClick={() => handleEditClick(item)}
                                >
                                  Edit
                                </Typography> */}
                                  </Box>
                                  {item?.user_type === 1 && (
                                    <Button
                                      variant="contained"
                                      sx={comStyle.xsStatusBtn}
                                    >
                                      <p>Answered By Admin</p>
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={comStyle.fScreenMainBox}>
            <Box sx={comStyle.xsMainBox}>
              <Box
                sx={comStyle.backArrowBox}
                onClick={() =>
                  window.location.replace(`${postData?.board_id?.public_link}`)
                }
              >
                <Box
                  component={"img"}
                  alt="leftArrow"
                  src="/images/leftArrow.svg"
                  sx={{ height: "14px" }}
                />{" "}
              </Box>
              <Typography
                onClick={() => window.open("https://feedmire.com/", "_blank")}
                sx={{ cursor: "pointer" }}
              >
                powered by <u style={{ color: "#112C00" }}>FeedVault</u>
              </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Box sx={comStyle.xsMatchBox}>
                {matchedLabel && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: `${matchedLabel.backgroundColor} !important`,
                      color: `${matchedLabel.color} !important`,
                      ...comStyle.matchedButton,
                    }}
                  >
                    <p> {matchedLabel.label}</p>
                  </Button>
                )}
                <Box sx={comStyle.xsPostDataMainBox}>
                  <Box sx={comStyle.xsAdminComment}>
                    <Box sx={comStyle.xsAdminCommentSubBox}>
                      <Typography sx={comStyle.xsACTitle}>
                        <p> {postData?.title} </p>
                      </Typography>
                      <Typography sx={comStyle.xsACDescription}>
                        <p> {postData?.description} </p>
                      </Typography>
                      <Box sx={comStyle.xsVotesBox}>
                        <Box
                          sx={{
                            ...comStyle.xsVoteBox,
                            backgroundColor: hasUserVoted
                              ? `${lightenHex(color, 0.3)} !important`
                              : "transparent",
                          }}
                          onClick={async () => {
                            const res = await addVote(
                              { post_id: id },
                              dispatch
                            );
                            if (res?.status === true) {
                              getPostData();
                            }
                          }}
                        >
                          <Typography sx={comStyle.xsVoteLength}>
                            {postData?.votes.length}{" "}
                            <span
                              style={{
                                fontSize: "1.1rem",
                                fontWeight: 500,
                                marginLeft: "8px",
                              }}
                            >
                              Votes
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={comStyle.xsAdminCommentDetail}>
                        <AccountCircleIcon />
                        <Typography sx={comStyle.postDataText}>
                          <p> {postData?.post_by} </p>
                        </Typography>
                        |
                        <Typography sx={comStyle.postDataText}>
                          <p> {formatDate(postData?.createdAt)} </p>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={comStyle.xsCommentBox}>
                    <Typography sx={comStyle.xsCommentLength}>
                      <p> {postData?.comments.length} Comments </p>
                    </Typography>

                    <Box sx={comStyle.commentFieldBox}>
                      <Box sx={comStyle.commentFieldSubBox}>
                        <OutlinedInput
                          placeholder="Leave a comment"
                          fullWidth
                          multiline
                          rows={3}
                          value={comment}
                          onChange={(e: any) => setComment(e.target.value)}
                          sx={comStyle.textField}
                        />
                        <Button
                          fullWidth
                          sx={{
                            bgcolor: lightenHex(color, 0.3) + "!important",
                            ...comStyle.submitBtn,
                            fontSize: "18px",
                            fontWeight: 600,
                            boxShadow: "none !important",
                          }}
                          onClick={handleSubmit}
                        >
                          <p> Submit </p>
                        </Button>
                      </Box>
                    </Box>

                    <Box sx={{ ...comStyle.replyBox, mt: "8px" }}>
                      {chats &&
                        chats.map((item: any, index: any) => {
                          const isExpanded = expandedComments[index] || false;
                          const isLongText = item.text.length > MAX_TEXT_LENGTH;
                          return (
                            <Box
                              sx={{
                                ...comStyle.chatsBox,
                                bgcolor:
                                  item?.user_type === 1
                                    ? "rgba(243, 243, 243, 1)"
                                    : "white",
                              }}
                              key={index}
                            >
                              <Box sx={comStyle.xsChatSubBox}>
                                <Typography sx={{ fontSize: "1.1rem" }}>
                                  <p>
                                    {" "}
                                    {isExpanded || !isLongText
                                      ? item.text
                                      : item.text.slice(0, XS_MAX_TEXT_LENGTH)}
                                    {isLongText && (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "blue",
                                        }}
                                        onClick={() =>
                                          toggleReadMore(index.toString())
                                        }
                                      >
                                        {isExpanded
                                          ? "...Read Less"
                                          : "...Read More"}
                                      </span>
                                    )}{" "}
                                  </p>
                                </Typography>
                                <Box sx={comStyle.xsChatReplyUserData}>
                                  <AccountCircleIcon />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...comStyle.postDataText,
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <p> {item.comment_by} </p>
                                    </Typography>
                                    |
                                    <Typography
                                      sx={{
                                        ...comStyle.postDataText,
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <p> {formatDate(item.time)} </p>
                                    </Typography>
                                  </Box>
                                </Box>
                                {item?.user_type === 1 && (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      ...comStyle.xsStatusBtn,
                                      my: 2,
                                      fontSize: "0.75rem",
                                      width: "10.5rem",
                                    }}
                                  >
                                    <p>Answered By Admin</p>
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: loading ? "90vh" : "100%",
      }}
    >
      <CircularProgress color="success" />
      <Typography sx={{ my: 4 }}>Loading...</Typography>
    </Box>
  );
};

export default Comments;
