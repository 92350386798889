// ** Service Imports
import apiCall from "../../../Helpers/Service";

// ** Slice Imports
import { getBoardData, setLoading, editBoardData } from "./BoadrdSlice";

export const fetchBoardData = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "board/fetchBoardList",
    data: passData,
    method: "post",
    isLogin: true,
  });

  if (res && res.status && res.data) {
    dispatch(getBoardData(res.data));
    dispatch(setLoading(false));
    console.error("Error fetching data: No valid response");
  }

  return res;
};

export const addBoardData = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "board/addBoard",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });
  
  if (res?.status) {
    dispatch(editBoardData(passData));
    dispatch(setLoading(false));
  }

  return res;
};

export const getBoardById = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));
  const data = await apiCall({
    method: "post",
    url: "board/findBoardById",
    data: passData,
  });
  dispatch(setLoading({ loading: false }));

  return data;
};

export const editBoard = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));
  const data = await apiCall({
    method: "post",
    url: "board/editBoard",
    data: passData,
  });

  dispatch(setLoading({ loading: false }));

  return data;
};

export const deleteBoard = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));

  const res = await apiCall({
    method: "post",
    url: "board/deleteBoard",
    data: passData,
    isLogin: true,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }
  return res;
};

export const fetchPostList = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/postList",
    data: passData,
    method: "post",
    isLogin: true,
  });

  if (res && res.status && res.data) {
    dispatch(setLoading(false));
  }

  return res;
};

export const deletePost = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));

  const res = await apiCall({
    method: "post",
    url: "post/deletePost",
    data: passData,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }
  return res;
};

export const changeStatus = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));
  const res = await apiCall({
    method: "post",
    url: "board/changePostStatus",
    data: passData,
  });

  dispatch(setLoading({ loading: false }));

  return res;
};

export const getPostById = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));
  const data = await apiCall({
    method: "post",
    url: "post/findPostById",
    data: passData,
  });
  dispatch(setLoading({ loading: false }));

  return data;
};

export const addPost = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/addPost",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }

  return res;
};

export const addComment = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/addComment",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }

  return res;
};

export const editComment = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/editComment",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }

  return res;
};

export const addVote = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/addVote",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }

  return res;
};

export const changePostStatus = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "post/changePostStatus",
    data: passData,
    method: "post",
    isLogin: true,
    isMultipart: false,
  });

  if (res?.status) {
    dispatch(setLoading(false));
  }

  return res;
};

export const userLogin = async (passData: any) => {
  const res = await apiCall({
    url: "user/authUser",
    data: passData,
    method: "POST",
  });

  return res;
};

export const getPublicLinkById = async (passData: any, dispatch: any) => {
  dispatch(setLoading({ loading: true }));
  const data = await apiCall({
    method: "post",
    url: "board/fetchBoardByPublicLink",
    data: passData,
  });
  dispatch(setLoading({ loading: false }));

  return data;
};

export const findUserById = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const data = await apiCall({
    method: "post",
    url: "user/userFindById",
    data: passData,
  });

  dispatch(setLoading(false));

  return data;
};
