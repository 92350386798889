import ColorsHelper from "../../../Helpers/Colors";

const dasStyle = {
  mainBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  companyTitleBox: {
    display: "flex",
    mb: 3,
    flexDirection: "column",
  },
  companyText: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "28px",
    textTransform: "capitalize",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    px: { xs: 3, sm: 1, md: 10 },
    mt: "0.5rem",
  },
  menuBtns: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: { sm: "70%", md: "auto" },
    scrollbarWidth: "none",
  },
  menuBtn: {
    mr: 1,
    color: ColorsHelper?.blackClr + "!important",
    fontSize: "17px",
    px: 2,
    py: 0.95,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid rgba(19, 19, 19, 0.2)`,
    width: "auto",
    boxShadow: "none !important",
  },
  createPostBtn: {
    color: "#131313 !important",
    fontSize: "17px",
    fontWeight: "bold",
    px: 2,
    py: 1,
    width: "10rem",
    borderRadius: "8px",
    textTransform: "capitalize",
    border: `1px solid rgba(19, 19, 19, 0.2)`,
    boxShadow: "none !important",
  },
  postList: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    display: "flex",
    width: "100%",
    borderRadius: "1.2rem",
  },
  postData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: 1.5,
    width: { sm: "72%", md: "80%" },
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    "@media (min-width: 900px) and (max-width: 1150px)": {
      width: "75%",
    },
  },
  titleBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 3,
  },
  title: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: { sm: "27ch", md: "43ch" },
    maxWidth: "100%",
    ml: { xs: 0, sm: 3, md: 3 },
    mr: { sm: 0, md: 3 },
  },
  btnLabel: {
    fontWeight: 600,
    fontSize: "0.8rem",
    // width: { sm: "8.7rem", md: "7.7rem" },
    borderRadius: "5px",
    boxShadow: "none !important",
  },
  descBox: {
    mt: 2,
    display: "flex",
    width: "81%",
    flexDirection: "column",
  },
  description: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "17px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ml: { xs: 0, sm: 3, md: 3 },
  },
  voteBox: {
    p: "8px 13px",
    width: { sm: "25rem", md: "13%" },
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    "&:hover .hoverIcon": {
      opacity: 1,
      transform: "translateY(0)",
    },
    cursor: "pointer",
  },
  hoverIcon: {
    position: "absolute",
    bottom: "-2.3rem",
    height: "2.3rem",
    width: "2.3rem",
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.3s ease, transform 0.3s ease",
    top: "2px",
  },

  numCount: { fontSize: "1.5rem", fontWeight: 600 },
  totText: { fontSize: { sm: "0.8rem", md: "1rem" }, fontWeight: 500, mt: 0.5 },
  commentBox: {
    p: 1.5,
    width: { sm: "29rem", md: "13%" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  //   xs Screen size

  xsMainBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  xsMainSubBox: {
    // p: 4,
    width: "100%",
    flexDirection: "column",
  },
  xsMainCompanyBox: {
    display: "flex",
    my: 2,
    ml: 3,
    flexDirection: "column",
  },
  xsCompanyBox: {
    display: "flex",
    mt: 1,
    // ml: 3,
    flexDirection: "column",
  },
  xsCompany: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "28px",
    textTransform: "capitalize",
  },
  xsFilterBox: {
    display: "flex",
    justifyContent: "space-between",
    px: { xs: 3, sm: 6, md: 10 },
    width: "100%",
  },
  select: {
    height: "2.8rem",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "& .MuiSvgIcon-root ": {
      fill: "black !important",
    },
    borderRadius: "0.8rem",
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  },
  xsCreatePost: {
    color: "#131313 !important",
    fontSize: "16px",
    fontWeight: 500,
    px: 2,
    py: 1,
    width: { xs: "100%", sm: "8rem", md: "8rem" },
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid rgba(19, 19, 19, 0.2)`,
  },
  xsPostList: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    display: "flex",
    width: "100%",
    borderRadius: "0.8rem",
    flexDirection: "column",
  },
  listBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: 1.5,
    width: "100%",
    cursor: "pointer",
  },
  xsTitleBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    mt: 2,
  },
  xsTitle: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "19px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "16ch",
    maxWidth: "100%",
  },
  xsDescBox: {
    mt: 1,
    display: "flex",
    width: "100%",
    // flexDirection: "column",
  },
  xsDescription: {
    color: ColorsHelper.blackClr,
    fontWeight: 500,
    fontSize: "15px",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "22ch",
  },
  xsCountBox: {
    py: 1,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    mt: 1,
  },
  xsCountText: { fontSize: "1rem", fontWeight: 500 },

  //   Add Post Dialog

  dialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "450px",
        borderRadius: "1.2rem",
      },
    },
  },

  dialogMainBox: {
    bgColor: ColorsHelper.whiteClr,
    border: `1px solid ${ColorsHelper.cardBorder}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    p: 2,
  },
  dialogTitle: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "1.2",
    p: 1.3,
    textAlign: "center",
  },
  formBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    px: { xs: 1, sm: 4, md: 4 },
    mt: 2,
  },
  formLabel: {
    color: ColorsHelper.blackClr + "!important",
    fontWeight: 600,
    fontSize: "15px",
  },
  textField: {
    mt: 1,
    borderRadius: "10px",
  },
  dialogCreatePostBtn: {
    mt: 2,
    color: ColorsHelper?.blackClr,
    fontSize: "16px",
    fontWeight: 600,
    px: 2,
    py: 0.9,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid black`,
    boxShadow: "none !important",
  },

  // Notify Dialog
  notifyDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "450px",
        borderRadius: "1.2rem",
      },
    },
  },
  notifyMainBox: {
    bgColor: ColorsHelper.whiteClr,
    border: `1px solid ${ColorsHelper.cardBorder}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    p: 2,
  },
  notifyTitle: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "1.2",
    p: { xs: "7px", sm: 1.3, md: 1.3 },
    textAlign: "center",
  },
  selectionBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    px: { xs: 0, sm: 4, md: 4 },
    mt: 3,
    flexDirection: { xs: "column", sm: "row", md: "row" },
    gap: { xs: 2, sm: 0, md: 0 },
  },
  noThanks: {
    bgcolor: "white !important",
    color: "black !important",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 600,
    py: 1,
    borderRadius: "0.7rem",
    borderColor: ColorsHelper.primary + "!important",
    width: "10rem",
    boxShadow: "none !important",
  },
  yesBtn: {
    bgcolor: ColorsHelper.primary + "!important",
    color: "black !important",
    textTransform: "capitalize",
    fontSize: "1rem",
    py: 1,
    fontWeight: 600,
    borderRadius: "0.7rem",
    width: "10rem",
    boxShadow: "none !important",
  },
  emailBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    px: { xs: 2, sm: 4, md: 4 },
    mt: 2,
  },
  submitBtn: {
    mt: 2,
    color: ColorsHelper?.blackClr,
    fontSize: "16px",
    fontWeight: 600,
    px: 2,
    py: 0.9,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid transparent`,
    boxShadow: "none !important",
  },
  loginOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export default dasStyle;
