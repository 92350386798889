// Third Party Imports
import CryptoJS from "crypto-js";

const AppUtils = {
  // encode the string function
  decodeStr: (str: any) => {
    return CryptoJS.AES.decrypt(
      str && str && str !== null && str !== undefined && str,
      "ADMIN_FV"
    )?.toString(CryptoJS.enc.Utf8);
  },

  // // decode the string function
  encodeStr: (str: string) => CryptoJS.AES.encrypt(str, "ADMIN_FV")?.toString(),

  // // getLoccalStorage Data
  setLocalStorage: (key: string, val: any) => localStorage.setItem(key, val),

  // // getLoccalStorage Data
  removeLocalStorageItem: (key: string) => localStorage.removeItem(key),

  // // setLoccalStorage Data
  getLocalStorage: (key: string) => {
    const data = typeof window !== "undefined" && localStorage.getItem(key);

    return (
      data &&
      data !== null &&
      data !== undefined &&
      JSON.parse(AppUtils.decodeStr(data))
    );
  },

  // check any value
  checkDirectValue: (field: any) => {
    let isData = false;
    if (typeof field !== "undefined" && field !== null) {
      if (typeof field === "string" && field?.trim() !== "") {
        isData = true;
      } else if (typeof field === "boolean") {
        isData = true;
      } else if (typeof field === "number") {
        isData = true;
      } else if (typeof field === "object") {
        isData = true;
      }
    }

    return isData;
  },

  checkFieldValue: (data: any, field: any) => {
    let isData = false;
    if (
      AppUtils.checkDirectValue(data) &&
      typeof data?.[field] !== "undefined" &&
      data?.[field] !== null
    ) {
      if (typeof data?.[field] === "string" && data?.[field]?.trim() !== "") {
        isData = true;
      } else if (typeof data?.[field] === "boolean") {
        isData = true;
      } else if (typeof data?.[field] === "number") {
        isData = true;
      } else if (typeof data?.[field] === "object") {
        isData = true;
      }
    }

    return isData;
  },

  checkWhiteSpace: (value: string | number) => {
    const spaceRegex = /^\s/;
    const stringValue = typeof value === "number" ? value.toString() : value;
    return spaceRegex.test(stringValue);
  },
};

export default AppUtils;
