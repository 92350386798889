// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface InitialAuthState {
  boardData: Object;
  isLoading: boolean;
  user: { email: string; picture: string } | null;
}

const initialAuthState: InitialAuthState = {
  boardData: {},
  isLoading: false,
  user: null,
};

const BoadrdSlice = createSlice({
  name: "board",
  initialState: initialAuthState,
  reducers: {
    getBoardData(state: any, action: any) {
      state.boardData = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    editBoardData(state: any, action: any) {
      state.boardData = { ...state.boardData, ...action.payload };
    },
    setUser(state: any, action: any) {
      state.user = action.payload;
    },
    clearUser(state: any) {
      state.user = null;
    },
  },
});

export const {
  getBoardData,
  setLoading,
  editBoardData,
  setUser,
  clearUser,
}: any = BoadrdSlice.actions;

export default BoadrdSlice.reducer;

export const board_data = (state: any) => state.about.boardData;

export const is_loading = (state: any) => state.about.isLoading;
