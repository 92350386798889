import ColorsHelper from "../../../Helpers/Colors";

const comStyle = {
  mainBox: { width: "100%", display: "flex", flexDirection: "column" },
  fScreenMainBox: {
    p: { xs: "10px", sm: "32px", md: "32px" },
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fscreenSubBox: {
    display: "flex",
    mb: 3,
    flexDirection: "column",
    ml: 5,
  },
  companyName: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "28px",
    textTransform: "capitalize",
  },
  contentBox: {
    px: { sm: 5, md: 24 },
    display: "flex",
  },
  backArrowBox: {
    bgcolor: ColorsHelper.secondary,
    borderRadius: "8px",
    padding: 1.5,
    width: "40px",
    height: "36px",
    "&:hover": {
      bgcolor: ColorsHelper.secondary,
    },
    ml: 1,
    cursor: "pointer",
  },
  dataBox: {
    pl: { sm: 3, md: 5 },
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  matchLabel: {
    // fontWeight: 700,
    // fontSize: "0.95rem",
    // width: "fit-content",
    // borderRadius: "10px",
    fontWeight: 600,
    fontSize: "0.8rem",
    // width: "7.7rem",
    borderRadius: "5px",
    boxShadow: "none !important",
  },
  postDataBox: { display: "flex", flexDirection: "column" },
  postDataSubBox: {
    display: "flex",
    mt: 3,
    justifyContent: "space-between",
  },
  mainPostData: {
    display: "flex",
    flexDirection: "column",
  },
  postDataTitle: { fontSize: "1.4rem", fontWeight: 600 },
  postDataDescription: { fontSize: "1.1rem", fontWeight: 500, mt: 2 },
  detailsBox: {
    display: "flex",
    gap: 1.5,
    mt: 2,
    alignItems: "center",
  },
  postDataText: { color: "#000000", fontWeight: 500 },
  delete: {
    cursor: "pointer",
    color: "#000000",
    fontWeight: 500,
    textDecoration: "underline",
  },
  voteBox: {
    width: { sm: "30%", md: "20%" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ml: 3.5,
  },
  voteSubBox: {
    py: 3.5,
    px: 4.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.8rem",
    bgcolor: ColorsHelper.whiteClr,
    cursor: "pointer",
  },
  voteCount: { fontSize: "1.4rem", fontWeight: 600 },
  voteText: { fontSize: "1rem", fontWeight: 500, mt: 0.5 },
  commentSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  commentBox: {
    display: "flex",
    mt: 8,
    bgcolor: ColorsHelper.whiteClr,
    borderRadius: "1rem",
    // mx: { sm: 4.5, md: 9 },
    p: 2,
    px: { sm: 4, md: 8 },
    flexDirection: "column",
    width: { xs: "100%", sm: "90%", md: "85%" },
  },
  commentLength: {
    textAlign: "end",
    width: "100%",
    fontWeight: 600,
    fontSize: "1.3rem",
  },
  writeCommentBox: {
    mt: 6,
    display: "flex",
    width: "100%",
  },
  userIcon: {
    width: "3rem",
    height: "3rem",
    mr: 1,
  },
  fieldBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textField: {
    mt: 1,
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.2)",
      borderWidth: 2,
    },
    "& .MuiOutlinedInput-input": {
      height: "0.7rem",
    },
  },
  submitBtn: {
    mt: 3,
    color: ColorsHelper?.blackClr,
    fontSize: "16px",
    fontWeight: 600,
    px: 2,
    py: 0.9,
    borderRadius: "10px",
    textTransform: "capitalize",
    borderColor: "rgba(0, 0, 0, 0.2)",
    boxShadow: "none",
  },
  replyBox: {
    display: "flex",
    flexDirection: "column",
    mt: 7,
  },
  adminReplyBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    p: 2,
    bgcolor: "rgba(243, 243, 243, 1)",
  },
  adminDataBox: {
    display: "flex",
    gap: { sm: 1, md: 1 },
    mt: 2,
    alignItems: "center",
  },
  dataText: {
    color: "black",
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  actionText: {
    cursor: "pointer",
    color: "black",
    fontWeight: 500,
    textDecoration: "underline",
    fontSize: { sm: "0.8rem", md: "0.9rem" },
  },
  answeredBtn: {
    bgcolor: ColorsHelper.primary + "!important",
    color: "black !important",
    fontWeight: 700,
    fontSize: "0.7rem",
    width: "10rem",
    mt: 2,
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  chatsBox: { mt: 3, display: "flex" },
  chatsSubBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    p: { xs: "16px 8px", sm: 2, md: 2 },
  },
  chatUserBox: {
    display: "flex",
    gap: { sm: 1, md: 1.5 },
    mt: 2,
    alignItems: "center",
  },

  //   xs Screen Design
  xsMainBox: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  xsMatchBox: {
    p: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  matchedButton: {
    fontWeight: 600,
    fontSize: "0.8rem",
    width: "7.7rem",
    borderRadius: "5px",
    boxShadow: "none !important",
  },
  xsPostDataMainBox: {
    display: "flex",
    flexDirection: "column",
  },
  xsAdminComment: {
    display: "flex",
    mt: 3,
    justifyContent: "space-between",
  },
  xsAdminCommentSubBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  xsACTitle: { fontSize: "1.1rem", fontWeight: 600 },
  xsACDescription: {
    fontSize: "1rem",
    fontWeight: 500,
    my: 2,
    color: "#3A3B3D",
  },
  xsVotesBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    // mt: 1,
  },
  xsVoteBox: {
    py: 1,
    px: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.8rem",
    bgcolor: ColorsHelper.whiteClr,
    width: "100%",
    cursor: "pointer",
  },
  xsVoteLength: { fontSize: "1.4rem", fontWeight: 600 },
  xsAdminCommentDetail: {
    display: "flex",
    gap: 1,
    mt: 2,
    alignItems: "center",
  },
  xsCommentBox: {
    display: "flex",
    mt: 8,
    bgcolor: ColorsHelper.whiteClr,
    borderRadius: "1rem",
    p: 2,
    flexDirection: "column",
  },
  xsCommentLength: {
    textAlign: "end",
    width: "100%",
    fontWeight: 600,
    fontSize: "1.3rem",
  },
  commentFieldBox: {
    mt: "20px",
    display: "flex",
    width: "100%",
  },
  commentFieldSubBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  xsAdminReplyBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    p: 1,
    bgcolor: "rgba(243, 243, 243, 1)",
  },
  xsReplyAdminData: {
    display: "flex",
    gap: 1,
    mt: 2,
    alignItems: "center",
  },
  xsStatusBtn: {
    fontWeight: 600,
    fontSize: "0.8rem",
    width: "11rem",
    borderRadius: "5px",
    boxShadow: "none !important",
    mt: 2,
    bgcolor: "#85EE44 !important",
    color: "black !important",
  },
  xsChatSubBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    p: 1,
  },
  xsChatReplyUserData: {
    display: "flex",
    gap: 1,
    mt: 2,
  },
};

export default comStyle;
