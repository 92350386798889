// React Import
import { useState } from "react";

// MUI Import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

const Footer = () => {
  const [email, setEmail] = useState();

  const pages = ["Home", "About", "Pricing", "FAQ"];
  const policy = ["Terms of services", "Privacy Policy"];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        bgcolor: "rgba(245, 245, 245, 1)",
        py: 3,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
          gap: { xs: "15px", sm: 1, md: 1 },
          p: { xs: "15px", sm: 0, md: 0 },
        }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component={"img"}
              src="/images/Fault Logo.svg"
              sx={{ width: "160px" }}
            />
            <Typography
              sx={{
                mt: 2,
                color: "#353636",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              <p> Gather Insights, Drive Growth </p>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6} sm={4.5}>
              {pages.map((item, index) => (
                <Typography key={index} sx={{ py: 1, fontWeight: 600 }}>
                  <p>{item}</p>
                </Typography>
              ))}
            </Grid>

            <Grid item xs={6} sm={7.5}>
              {policy.map((item, index) => (
                <Typography key={index} sx={{ py: 1, fontWeight: 600 }}>
                  <p> {item}</p>
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <Typography
            sx={{ color: "black", fontWeight: 600, fontSize: "22px" }}
          >
            <p>Have Questions?</p>
          </Typography>
          <OutlinedInput
            placeholder="John.doe@email.com"
            fullWidth
            id="boardName"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            sx={{
              mt: 2,
              borderRadius: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#235A00 !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#235A00 !important",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#235A00 !important",
                borderWidth: 2,
              },
            }}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth
            sx={{
              mt: 1,
              bgcolor: "#85EE44 !important",
              color: "black",
              fontWeight: 700,
              borderRadius: "10px",
              textTransform: "none",
              boxShadow: "none !important",
            }}
          >
            <p> Submit </p>
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: { xs: "50px", sm: "80px", md: "80px" },
          width: "100%",
          display: "flex",
          justifyContent: "center",
          maxWidth: "1200px",
        }}
      >
        <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
          <p> Copyright @ 2024 - All rights reserved</p>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
