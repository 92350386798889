const ColorsHelper = {
    whiteClr: "#FFF",
    blackClr: "#000",
    primary: "#85EE44",
    secondary: "#131313",
    lightClr: '#000000A6',
    cardBorder: '#00000033',
    bgLight: 'red'
};

export default ColorsHelper;
