// React Imports
import { useEffect, useState } from "react";

//  MUI Imports
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress, useTheme } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

// Helper Imports
import ColorsHelper from "../../../Helpers/Colors";

// Redux Imports
import {
  fetchPostList,
  addPost,
  getPublicLinkById,
  addVote,
} from "../../../Redux/Reducers/Board/BoardActions";

// Css Import
import dasStyle from "./Dashboard.style";
import AppUtils from "../../../Helpers/AppUtils";

interface Data {
  title: string;
  description: string;
  email: string;
}

const Dashboard = (props: any) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [postList, setPostList] = useState<any>(null);
  const [color, setColor] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: 0,
    label: "All",
  });
  const [IsAddPost, setAddPost] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [isBoardId, setBoardId] = useState<any>(null);
  const [companyName, setCompanyName] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uData, setUData] = useState("");
  const [noData, setNoData] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const [postLoad, setPostLoad] = useState<any>(true);

  const getBoardBypublicLink = async (publicLink: string) => {
    const res = await getPublicLinkById({ public_link: publicLink }, dispatch);
    if (res?.status) {
      setBoardId(res?.data?._id);
      setCompanyName(res?.data?.company_name);
      setColor(res?.data?.theme_color);
      setLoading(false);
    } else {
      setNoData(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    getBoardBypublicLink(currentUrl);
  }, []);

  const getPostList = async () => {
    const res = await fetchPostList(
      {
        startToken: 1,
        endToken: 20,
        board_id: isBoardId,
        status: selectedStatus.id,
      },
      dispatch
    );
    if (res?.status) {
      setPostLoad(false);
      setPostList(res?.data);
    }
  };

  useEffect(() => {
    if (isBoardId) {
      getPostList();
    }
  }, [isBoardId, selectedStatus]);

  const buttonLabels = [
    { id: 0, label: "All" },
    {
      id: 1,
      label: "In-Progress",
      backgroundColor: "rgba(240, 229, 192, 1)",
      color: "rgba(54, 130, 7, 1)",
    },
    {
      id: 2,
      label: "Not Planned",
      backgroundColor: "rgb(220 185 130)",
      color: "rgb(158 71 13)",
    },
    {
      id: 3,
      label: "Answered",
      backgroundColor: "rgba(74, 235, 154, 0.8)",
      color: "rgba(255, 255, 255, 0.8)",
    },
    {
      id: 4,
      label: "No Status",
      backgroundColor: "rgba(240, 195, 192, 1)",
      color: "rgba(130, 29, 7, 1)",
    },
  ];

  const getStatusStyles = (statusId: any) => {
    const status = buttonLabels.find((label) => label.id === statusId);
    return status
      ? {
          label: status.label,
          backgroundColor: status.backgroundColor,
          color: status.color,
        }
      : { label: "Unknown", backgroundColor: "gray", color: "white" };
  };

  const initialValues: Data = {
    title: "",
    description: "",
    email: "",
  };

  const schema = yup.object({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    email: yup.string().nullable(),
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: any) => {
      const postData = {
        board_id: isBoardId,
        title: value.title.trim(),
        description: value.description.trim(),
        ...(sendEmail && { post_by: uData }),
      };

      const res: any = await addPost(postData, dispatch);

      if (res?.status === true) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        setSendEmail(false);
        setAddPost(false);
        formik.resetForm();

        getPostList();
      } else {
        enqueueSnackbar(res?.message, {
          variant: "error",
        });
        setSendEmail(false);
        setAddPost(false);
        formik.resetForm();
      }
    },
  });

  const handleMessageClick = (message: any) => {
    navigate(`/post/${message._id}`);
  };

  const handleFilterChange = (event: any) => {
    const selected = buttonLabels.find(
      (label) => label.id === event.target.value
    );
    setSelectedStatus(selected);
  };

  function lightenHex(hex: any, percent: any) {
    const num = parseInt(hex?.replace("#", ""), 16),
      amt = Math.round(2.55 * percent * 100),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }

  useEffect(() => {
    const userData: any = JSON.parse(localStorage.getItem("comUser") as any);
    formik.setFieldValue("email", userData?.email);
  }, []);

  const authSuccess = () => {
    setSendEmail(true);
    formik.handleSubmit();
    setIsNotify(false);
  };

  const handleVoteClick = async (message: any) => {
    const storedMessageIds: any =
      JSON.parse(localStorage.getItem("votedMessageIds") as any) || [];

    if (!storedMessageIds.includes(message._id)) {
      const res = await addVote({ post_id: message._id }, dispatch);

      if (res?.status === true) {
        storedMessageIds.push(message._id);

        localStorage.setItem(
          "votedMessageIds",
          JSON.stringify(storedMessageIds)
        );

        getPostList();
      }
    } else {
      enqueueSnackbar("You have already voted for this post.", {
        variant: "info",
      });
    }
  };

  const votedMessageIds =
    JSON.parse(localStorage.getItem("votedMessageIds") as any) || [];

  return !loading ? (
    <>
      {noData ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            component={"img"}
            src="/images/noPage.png"
            sx={{ height: "28rem" }}
          />
        </Box>
      ) : (
        <>
          {!isXs ? (
            <Box sx={dasStyle.mainBox}>
              <Box
                sx={{
                  p: 4,
                  ...dasStyle.mainBox,
                  px: { sm: 4, md: 10 },
                }}
              >
                <Box sx={dasStyle.companyTitleBox}>
                  <Typography sx={dasStyle.companyText}>
                    <p>
                      {" "}
                      {companyName
                        ? companyName?.replace(/-/g, " ")
                        : "My Circle"}{" "}
                    </p>
                  </Typography>
                  <Typography
                    onClick={() =>
                      window.open("https://feedmire.com/", "_blank")
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <p>
                      {" "}
                      powered by <u style={{ color: "#112C00" }}>
                        FeedVault
                      </u>{" "}
                    </p>
                  </Typography>
                </Box>
                <Box sx={dasStyle.filterBox}>
                  <Box sx={dasStyle.menuBtns}>
                    {buttonLabels.map((label: any) => (
                      <Button
                        key={label.id}
                        onClick={() => setSelectedStatus(label)}
                        sx={{
                          bgcolor:
                            selectedStatus?.id === label.id
                              ? lightenHex(color, 0.3)
                              : "transparent",
                          "&:hover": {
                            bgcolor:
                              selectedStatus?.id === label.id
                                ? lightenHex(color, 0.3)
                                : "transparent",
                            color:
                              selectedStatus?.id === label.id
                                ? ColorsHelper.whiteClr
                                : ColorsHelper.blackClr,
                          },
                          cursor: "default",
                          fontWeight:
                            selectedStatus?.id === label.id ? 800 : 600,
                          ...dasStyle.menuBtn,
                        }}
                      >
                        <p>{label?.label}</p>
                      </Button>
                    ))}
                  </Box>
                  <Button
                    sx={{
                      bgcolor: lightenHex(color, 0.3) + "!important",
                      ...dasStyle.createPostBtn,
                      fontWeight: "600 !important",
                    }}
                    onClick={() => setAddPost(true)}
                  >
                    <p>Create Post</p>
                  </Button>
                </Box>

                <Box sx={{ px: { xs: 3, sm: 1, md: 10 } }}>
                  {!postLoad ? (
                    <>
                      {postList &&
                        postList?.map((message: any, index: any) => {
                          const statusStyles = getStatusStyles(message.status);
                          const hasUserVoted = votedMessageIds.includes(
                            message?._id
                          );
                          return (
                            <Box
                              sx={{
                                mt: 4,
                                display: "flex",
                                backgroundColor: "white",
                                borderRadius: "1rem",
                                minHeight: "7rem",
                              }}
                              key={index}
                            >
                              <Box sx={dasStyle.postList}>
                                <Box
                                  sx={dasStyle.postData}
                                  onClick={() => handleMessageClick(message)}
                                >
                                  <Box sx={dasStyle.titleBox}>
                                    <Typography sx={dasStyle.title}>
                                      {message?.title}
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        bgcolor: `${statusStyles.backgroundColor} !important`,
                                        color: `${statusStyles.color} !important`,
                                        ...dasStyle.btnLabel,
                                        width: "max-content",
                                      }}
                                    >
                                      <p> {statusStyles.label}</p>
                                    </Button>
                                  </Box>
                                  <Box sx={dasStyle.descBox}>
                                    <Typography sx={dasStyle.description}>
                                      {message?.description}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    ...dasStyle.voteBox,
                                    backgroundColor: hasUserVoted
                                      ? `${lightenHex(color, 0.3)} !important`
                                      : "transparent",
                                    opacity: hasUserVoted && 1,
                                    transform: hasUserVoted && "translateY(0)",
                                    "&:hover": {
                                      backgroundColor: `${lightenHex(
                                        color,
                                        0.3
                                      )}!important`,
                                    },
                                  }}
                                  onClick={() => handleVoteClick(message)}
                                >
                                  {!hasUserVoted && (
                                    <KeyboardArrowUpIcon
                                      sx={dasStyle.hoverIcon}
                                      className="hoverIcon"
                                    />
                                  )}
                                  <Typography sx={dasStyle.numCount}>
                                    <p>{message?.vote_count}</p>
                                  </Typography>
                                  <Typography sx={dasStyle.totText}>
                                    <p>Votes</p>
                                  </Typography>
                                </Box>
                                <Box
                                  className="comment"
                                  sx={dasStyle.commentBox}
                                >
                                  <Typography sx={dasStyle.numCount}>
                                    <p>{message?.comment_count}</p>
                                  </Typography>
                                  <Typography sx={dasStyle.totText}>
                                    <p> Comments </p>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        mt: { xs: 10, sm: 35, md: 16 },
                      }}
                    >
                      <CircularProgress color="success" />
                      <Typography sx={{ my: 4 }}>Loading...</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={dasStyle.xsMainBox}>
              <Box sx={dasStyle.xsMainSubBox}>
                <Box sx={dasStyle.xsMainCompanyBox}>
                  <Typography sx={dasStyle.xsCompany}>
                    <p>
                      {" "}
                      {companyName
                        ? companyName?.replace(/-/g, " ")
                        : "My Circle"}{" "}
                    </p>
                  </Typography>
                  <Typography
                    onClick={() =>
                      window.open("https://feedmire.com/", "_blank")
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    powered by <u style={{ color: "#112C00" }}>FeedVault</u>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...dasStyle.xsFilterBox,
                    display: "flex",
                    gap: 3,
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedStatus.id}
                      defaultValue={selectedStatus.id}
                      onChange={handleFilterChange}
                      sx={dasStyle.select}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {buttonLabels.map((key: any) => (
                        <MenuItem value={key.id} key={key.id}>
                          <Typography>
                            <p>{key.label} </p>
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    sx={{
                      bgcolor: lightenHex(color, 0.3) + "!important",
                      ...dasStyle.xsCreatePost,
                    }}
                    fullWidth
                    onClick={() => setAddPost(true)}
                  >
                    <p> Create Post </p>
                  </Button>
                </Box>

                <Box sx={{ px: { xs: 3 } }}>
                  {!postLoad ? (
                    <>
                      {postList &&
                        postList?.map((message: any, index: any) => {
                          const statusStyles = getStatusStyles(message.status);
                          const hasUserVoted = votedMessageIds.includes(
                            message?._id
                          );
                          return (
                            <Box sx={{ mt: 3, display: "flex" }} key={index}>
                              <Box sx={dasStyle.xsPostList}>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box
                                    sx={dasStyle.listBox}
                                    onClick={() => handleMessageClick(message)}
                                  >
                                    <Box>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          bgcolor: `${statusStyles.backgroundColor} !important`,
                                          color: `${statusStyles.color} !important`,
                                          ...dasStyle.btnLabel,
                                        }}
                                      >
                                        <p> {statusStyles.label}</p>
                                      </Button>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "80%",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Box sx={dasStyle.xsTitleBox}>
                                          <Typography sx={dasStyle.xsTitle}>
                                            {message?.title}
                                          </Typography>
                                        </Box>
                                        <Box sx={dasStyle.xsDescBox}>
                                          <Typography
                                            sx={dasStyle.xsDescription}
                                          >
                                            {message?.description}
                                          </Typography>
                                        </Box>
                                        <Box sx={dasStyle.xsCompanyBox}>
                                          <Typography sx={dasStyle.xsCountText}>
                                            {message?.comment_count} Comments
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          mb: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            py: 1,
                                            px: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            border:
                                              "1px solid rgba(0, 0, 0, 0.2)",
                                            borderRadius: "0.8rem",
                                            cursor: "pointer",
                                            backgroundColor: hasUserVoted
                                              ? `${lightenHex(
                                                  color,
                                                  0.3
                                                )} !important`
                                              : "transparent",
                                          }}
                                          onClick={() =>
                                            handleVoteClick(message)
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "1.4rem",
                                              fontWeight: 600,
                                            }}
                                          >
                                            <p> {message?.vote_count} </p>
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "1rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            <p> Votes </p>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        mt: 24,
                      }}
                    >
                      <CircularProgress color="success" />
                      <Typography sx={{ my: 4 }}>Loading...</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          {isLoggedIn && (
            <Dialog
              open={isLoggedIn}
              onClose={() => {
                setIsLoggedIn(false);
              }}
              maxWidth="sm"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                    borderRadius: "1.2rem",
                  },
                },
              }}
            >
              <Box
                sx={{
                  bgcolor: ColorsHelper.whiteClr,
                  border: `1px solid ${ColorsHelper.cardBorder}`,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    color: ColorsHelper.blackClr,
                    fontWeight: 600,
                    fontSize: "2rem",
                    lineHeight: "1.2",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  <p> Login </p>
                </Typography>
                <Typography
                  sx={{
                    color: ColorsHelper.blackClr,
                    fontWeight: 500,
                    fontSize: "17px",
                    lineHeight: "1.2",
                    p: { xs: 1, sm: 1.3, md: 1.3 },
                    textAlign: "center",
                    my: 1.5,
                  }}
                >
                  <p>
                    {" "}
                    Login to join the community and start sharing your expertise
                    and solutions today!{" "}
                  </p>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    px: 4,
                    m: "20px 0px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <GoogleLogin
                    {...props}
                    onSuccess={(response: any) => {
                      const user: any = jwtDecode(response.credential);
                      const email = user.email;
                      const profile_image = user.picture;
                      const name = user.name;
                      localStorage.setItem(
                        "comUser",
                        JSON.stringify({ email, profile_image, name })
                      );
                      setUData(user.email);
                      authSuccess();
                      setIsLoggedIn(false);
                      enqueueSnackbar("Logged in successfully!", {
                        variant: "success",
                      });
                    }}
                    onError={(error: any) =>
                      console.error("Login Failed", error)
                    }
                    useOneTap
                  />
                </Box>
              </Box>
            </Dialog>
          )}

          <Dialog
            open={IsAddPost}
            onClose={() => {
              setAddPost(false);
              formik.resetForm();
            }}
            maxWidth="sm"
            sx={dasStyle.dialog}
          >
            <Box sx={dasStyle.dialogMainBox}>
              <Typography sx={dasStyle.dialogTitle}>
                <p> What features would you like to see? </p>
              </Typography>
              <Box sx={dasStyle.formBox}>
                <FormControl
                  fullWidth
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  sx={{ mt: 1 }}
                >
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={dasStyle.formLabel}
                  >
                    <p>Title</p>
                  </FormLabel>
                  <OutlinedInput
                    placeholder="Enter Title"
                    fullWidth
                    id="title"
                    value={formik.values.title}
                    onChange={(e: any) => {
                      if (!AppUtils.checkWhiteSpace(e.target.value)) {
                        formik.setFieldValue("title", e.target.value);
                      }
                    }}
                    sx={{
                      ...dasStyle.textField,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                        borderWidth: 2,
                      },
                    }}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <FormHelperText>{formik.errors.title}</FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  sx={{ mt: 2 }}
                >
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={dasStyle.formLabel}
                  >
                    <p> Description</p>
                  </FormLabel>
                  <OutlinedInput
                    placeholder="Enter Description"
                    fullWidth
                    multiline
                    rows={5}
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    sx={{
                      ...dasStyle.textField,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                        borderWidth: 2,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: "0.7rem",
                      },
                    }}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <FormHelperText>{formik.errors.description}</FormHelperText>
                  ) : null}
                </FormControl>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: lightenHex(color, 0.3) + "!important",
                    ...dasStyle.dialogCreatePostBtn,
                  }}
                  onClick={() => {
                    if (
                      formik.values.title.trim() !== "" &&
                      formik.values.description.trim() !== ""
                    ) {
                      setAddPost(false);
                      setIsNotify(true);
                    } else {
                      formik.setFieldTouched("title", true);
                      formik.setFieldTouched("description", true);
                    }
                  }}
                >
                  <p>Create Post</p>
                </Button>
              </Box>

              <Typography
                sx={{ mt: 3, cursor: "pointer" }}
                onClick={() => window.open("https://feedmire.com/", "_blank")}
              >
                powered by <u style={{ color: "#112C00" }}>FeedVault</u>
              </Typography>
            </Box>
          </Dialog>

          <Dialog
            open={isNotify}
            onClose={() => {
              setIsNotify(false);
              formik.resetForm();
            }}
            maxWidth="sm"
            sx={dasStyle.notifyDialog}
          >
            <Box sx={dasStyle.notifyMainBox}>
              <Typography sx={dasStyle.notifyTitle}>
                <p>
                  {" "}
                  Do you want to be notified about any updates to this post?
                </p>
              </Typography>

              <Box sx={dasStyle.selectionBox}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    ...dasStyle.noThanks,
                    border: `1px solid black !important`,
                  }}
                  onClick={() => {
                    setIsNotify(false);
                    formik.handleSubmit();
                  }}
                >
                  <p>No, Thanks</p>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ...dasStyle.yesBtn,
                    bgcolor: lightenHex(color, 0.3) + "!important",
                    border: "1px solid black",
                  }}
                  onClick={() => {
                    // formik.handleSubmit();
                    // setIsNotify(false);
                    // setSendEmail(true);
                    setIsLoggedIn(true);
                  }}
                >
                  <p>Yes, Notify me</p>
                </Button>
              </Box>

              <Typography
                sx={{ mt: 3, cursor: "pointer" }}
                onClick={() => window.open("https://feedmire.com/", "_blank")}
              >
                powered by <u style={{ color: "#112C00" }}>FeedVault</u>
              </Typography>
            </Box>
          </Dialog>
        </>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: loading ? "90vh" : "100%",
      }}
    >
      <CircularProgress color="success" />
      <Typography sx={{ my: 4 }}>Loading...</Typography>
    </Box>
  );
};

export default Dashboard;
