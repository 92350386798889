// React Import
import { useEffect, useState } from "react";

// MUI Import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";

// Third party Import
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { MuiColorInput } from "mui-color-input";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Redux Import
import {
  addBoardData,
  fetchBoardData,
} from "../../../../Redux/Reducers/Board/BoardActions";

// Css Import
import Bstyle from "./Board.style";

import AppUtils from "../../../../Helpers/AppUtils";
import Consthelper from "../../../../Helpers/Constants";

interface Data {
  board_name: string;
  company_name: string;
  theme_color: string;
  public_link: string;
  embed_code: string;
}

const PageOne = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOptions, setIsOption] = useState(false);
  const [value, setValue] = useState("#ffffff");
  const [boards, setBoards] = useState<any>(null);
  const [generatedPublicLink, setGeneratedPublicLink] = useState("");
  const [copiedType, setCopiedType] = useState<"link" | "iframe" | null>(null);
  const [created, setCreated] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("ADMIN_FV");

    if (!user) {
      navigate("/");
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  }, []);

  const fetchBlogData = async () => {
    const res = await fetchBoardData(
      {
        startToken: 1,
        endToken: 10,
        search: "",
      },
      dispatch
    );

    setBoards(res?.data);
    getLink();
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  const initialValues: Data = {
    board_name: "",
    company_name: "",
    theme_color: "",
    public_link: "",
    embed_code: "",
  };

  const schema = yup.object({
    board_name: yup
      .string()
      .matches(Consthelper.nameRegex, "Add Proper Board Name")
      .required("Board name is required"),
    company_name: yup.string().required("Company name is required"),
    theme_color: yup.string().required("Theme color is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: async (value) => {
      const res: any = await addBoardData(
        {
          board_name: value?.board_name.trim(),
          company_name: value?.company_name.trim(),
          theme_color: value?.theme_color.trim(),
        },
        dispatch
      );

      if (res?.status === true) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        setCreated(true);
        fetchBlogData();
      } else {
        enqueueSnackbar(res?.message, {
          variant: "error",
        });
      }
    },
  });

  const handleCopy = (value: any, type: "link" | "iframe") => {
    if (value.trim() === "") {
      enqueueSnackbar("You can't copy an empty link", {
        variant: "error",
      });
      return;
    }

    navigator.clipboard.writeText(value);

    const message =
      type === "link"
        ? "Link copied successfully"
        : "iframe link copied successfully";

    enqueueSnackbar(message, {
      variant: "success",
    });

    setCopiedType(null);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
    formik.setFieldValue("theme_color", newValue);
  };

  const getLink = () => {
    boards?.map((item: any) => {
      if (item?.board_name === formik.values.board_name) {
        setGeneratedPublicLink(item?.public_link);
      }
    });
  };

  useEffect(() => {
    getLink();
  }, [boards]);

  useEffect(() => {
    if (generatedPublicLink) {
      formik.setFieldValue(
        "embed_code",
        `<iframe src="${generatedPublicLink}" width="100%" height="800px"></iframe>`
      );
    }
  }, [generatedPublicLink]);

  return (
    <>
      {!isOptions && (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={Bstyle.navigateArrowBox}
            onClick={() => {
              navigate("/card");
              setCreated(false);
            }}
          >
            <Box
              component={"img"}
              alt="leftArrow"
              src="/images/leftArrow.svg"
              sx={{ height: "14px" }}
            />
          </Box>
          <Box sx={Bstyle.createMainBox}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                mt: { xs: 4, sm: 0, md: 0 },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={Bstyle.mainCreateBox}>
                  <Typography sx={Bstyle.boxHeading}>
                    <p> Collect User Feedback with Dedicated Boards! </p>
                  </Typography>
                  <Typography sx={Bstyle.boxSubText}>
                    <p>
                      {" "}
                      Develop boards for collecting valuable user insights and
                      improving services.
                    </p>
                  </Typography>

                  <FormControl
                    fullWidth
                    error={
                      formik.touched.board_name &&
                      Boolean(formik.errors.board_name)
                    }
                  >
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      sx={Bstyle.formLabel}
                    >
                      <p> Board Name </p>
                    </FormLabel>
                    <OutlinedInput
                      placeholder="Enter Board Name"
                      fullWidth
                      id="board_name"
                      value={formik.values.board_name}
                      onChange={(e: any) => {
                        if (!AppUtils.checkWhiteSpace(e.target.value)) {
                          formik.setFieldValue("board_name", e.target.value);
                          formik.validateField("board_name");
                        }
                      }}
                      sx={Bstyle.textField}
                    />
                    {formik.touched.board_name && formik.errors.board_name ? (
                      <FormHelperText>
                        {formik.errors.board_name}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                  <Button
                    fullWidth
                    sx={Bstyle.createbtn}
                    onClick={() => {
                      if (formik.values.board_name.trim() !== "") {
                        setIsOption(true);
                      } else {
                        formik.setFieldTouched("board_name", true, false);
                        formik.setFieldError(
                          "board_name",
                          "Board name is required"
                        );
                      }
                    }}
                  >
                    <p>Create Board</p>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: { xs: "100%", sm: "13.5rem", md: "13.5rem" },
                ml: { xs: 0, sm: 8, md: 8.2 },
                display: "flex",
                alignItems: "center",
                mt: { xs: 3, sm: 2, md: 0 },
                mb: { xs: 0, sm: 3, md: 3 },
              }}
            >
              <Button
                sx={{
                  bgcolor: "#85EE44 !important",
                  color: "#000000 !important",
                  fontSize: "15px",
                  fontWeight: 600,
                  px: 1,
                  py: 1.5,
                  borderRadius: "16px",
                  textTransform: "capitalize",
                  border: `1px solid transparent`,
                  boxShadow: "none !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "normal", md: "normal" },
                  width: "100%",
                }}
              >
                <Box
                  component={"img"}
                  src="/images/chat.svg"
                  sx={{ mr: { xs: 0, sm: 1, md: 1 }, mt: 0.5 }}
                />
                <p>Write your feedback</p>
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {isOptions && (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{ ...Bstyle.custNavigateArrow, mt: 3 }}
            onClick={() => setIsOption(false)}
          >
            <Box
              component={"img"}
              alt="leftArrow"
              src="/images/leftArrow.svg"
              sx={{ height: "14px" }}
            />
          </Box>
          <Box
            sx={{
              my: 1,
              mx: { xs: 2, sm: 0, md: 0 },
              mt: { xs: 2, sm: 0, md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography align="center" sx={Bstyle.custBoardText}>
                <p> Customize Your Board </p>
              </Typography>
              <Typography
                align="center"
                sx={{
                  ...Bstyle.custBoardSubText,
                  width: { xs: "23rem", sm: "26rem", md: "27rem" },
                }}
              >
                <p>
                  Personalize your board with company details and integration
                  links.
                </p>
              </Typography>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 3 }}
            >
              <Grid item xs={12} sm={7} md={4.5}>
                <Box sx={Bstyle.formMainBox}>
                  <Typography sx={Bstyle.formBoxTitle}>
                    <p> Home Page Suggestions </p>
                  </Typography>

                  <Divider sx={{ width: "100%" }} />

                  <Box sx={Bstyle.formBox}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.board_name &&
                        Boolean(formik.errors.board_name)
                      }
                      sx={{ mt: 2 }}
                    >
                      <FormLabel sx={Bstyle.formLabel}>
                        <p>Enter your company name</p>
                      </FormLabel>
                      <OutlinedInput
                        placeholder="Enter Company Name"
                        fullWidth
                        id="company_name"
                        value={formik.values.company_name}
                        onChange={(e: any) => {
                          if (!AppUtils.checkWhiteSpace(e.target.value)) {
                            formik.setFieldValue(
                              "company_name",
                              e.target.value
                            );
                          }
                        }}
                        sx={Bstyle.textField}
                      />
                      {formik.touched.company_name &&
                      formik.errors.company_name ? (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formik.errors.company_name}
                        </FormHelperText>
                      ) : null}
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <FormLabel sx={Bstyle.formLabel}>
                        <p>Select Theme Color</p>
                      </FormLabel>
                      <MuiColorInput
                        format="hex"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "1rem",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black !important",
                            borderWidth: 2,
                          },
                          mt: 1,
                        }}
                      />
                      {formik.touched.theme_color &&
                        formik.errors.theme_color && (
                          <FormHelperText style={{ color: "red" }}>
                            {formik.errors.theme_color}
                          </FormHelperText>
                        )}
                    </FormControl>
                    {created && (
                      <>
                        <FormControl fullWidth sx={{ mt: 2.5 }}>
                          <FormLabel sx={Bstyle.formLabel}>
                            <p>Public Link</p>
                          </FormLabel>
                          <OutlinedInput
                            placeholder="Enter Public Link"
                            fullWidth
                            id="public_link"
                            name="public_link"
                            value={generatedPublicLink}
                            disabled
                            endAdornment={
                              <Box
                                component={"img"}
                                onClick={() => {
                                  handleCopy(generatedPublicLink, "link");
                                }}
                                sx={Bstyle.copyBtn}
                                src="/images/copy.png"
                              />
                            }
                            sx={Bstyle.textField}
                          />
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2.5 }}>
                          <FormLabel sx={Bstyle.formLabel}>
                            <p>Embed Code</p>
                          </FormLabel>
                          <OutlinedInput
                            placeholder="Enter Embed Code"
                            fullWidth
                            id="embed_code"
                            name="embed_code"
                            value={formik.values.embed_code}
                            disabled
                            onChange={(e: any) => {
                              if (!AppUtils.checkWhiteSpace(e.target.value)) {
                                formik.setFieldValue(
                                  "embed_code",
                                  e.target.value
                                );
                              }
                            }}
                            endAdornment={
                              <Box
                                component={"img"}
                                onClick={() => {
                                  handleCopy(
                                    formik.values.embed_code,
                                    "iframe"
                                  );
                                }}
                                sx={Bstyle.copyBtn}
                                src="/images/copy.png"
                              />
                            }
                            sx={Bstyle.textField}
                          />
                        </FormControl>
                      </>
                    )}

                    <Button
                      fullWidth
                      sx={Bstyle.createBtn}
                      onClick={() => {
                        created ? navigate("/card") : formik.handleSubmit();
                      }}
                    >
                      {created ? <p>Visit Board</p> : <p>Create</p>}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PageOne;
