import Box from "@mui/material/Box";
import Hero from "./Hero";
import Collection from "./Collection";
import Testimonial from "./Testimonial";
import Branding from "./Branding";
import Testimonial2 from "./Testimonial 2";
import Customer from "./Customer";
import FAQ from "./FAQ";
import GetStart from "./GetStart";
import Footer from "./Footer";

const Home = () => {
  return (
    <Box sx={{ bgcolor: "white" }}>
      <Hero />
      <Collection />
      <Testimonial />
      <Branding />
      <Testimonial2 />
      <Customer />
      <FAQ />
      <GetStart />
      <Footer />
    </Box>
  );
};

export default Home;
