// MUI Imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/Star";

const Testimonial2 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 2,
        my: 5,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          component={"img"}
          src="/images/quote.svg"
          sx={{
            color: "rgba(82, 165, 32, 1)",
            mb: 6,
          }}
        />
        <Typography
          sx={{
            color: "rgba(53, 54, 54, 1)",
            fontSize: "1.13rem",
            fontWeight: 400,
            maxWidth: "600px",
            mb: 3,
          }}
        >
          <p>
            Customizing our boards with our brand colors and logo has
            strengthened our connection with customers.
          </p>
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          {[...Array(5)].map((_, index) => (
            <Grid item key={index}>
              <StarIcon sx={{ color: "#C8A800", width: "1.3rem" }} />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Avatar
            src="/images/tm1.png"
            alt="Steve Pole"
            sx={{ width: 56, height: 56, mr: 1 }}
          />
          <Box sx={{ textAlign: "start" }}>
            <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
              <p> Roy Federal </p>
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", color: "rgba(53, 54, 54, 1)" }}
            >
              <p> Roger Wills Co. </p>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonial2;
