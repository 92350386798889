// React Imports
import React, { useEffect, useState } from "react";

// MUI Imports
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Authentication from "../Authentication";

// Third Party Import
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// Redux Import
import { setUser } from "../../../../Redux/Reducers/Board/BoadrdSlice";

// CSS Imports
import headerStyle from "./Header.style";
import ColorsHelper from "../../../../Helpers/Colors";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { email = "", picture = "" } =
    useSelector((state: any) => state?.board?.user) || {};

  const [nEmail, setEmail] = useState("");
  const [nPicture, setPicture] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [Auth, setAuth] = useState<string | null>(null);
  const [openLogout, setOpenLogout] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState<any>(null);

  useEffect(() => {
    const authData = localStorage.getItem("ADMIN_FV");
    const userData = JSON.parse(localStorage.getItem("userData") ?? "{}");
    setAuth(authData);
    if (!email && !picture && userData) {
      dispatch(setUser(userData));
    }
    if (userData) {
      setEmail(userData.email || "");
      setPicture(userData.picture || "");
    }
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    localStorage.removeItem("ADMIN_FV");
    localStorage.removeItem("userData");
    dispatch(setUser({ email: null, picture: null }));
    setAuth(null);
    setOpenLogout(false);
    enqueueSnackbar("Logged out successfully", { variant: "success" });
    navigate("/");
    setUserId(null);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          ...headerStyle.main,
          backgroundColor:
            window.location.pathname === "/"
              ? "rgba(246, 255, 241, 1)"
              : "rgba(255, 255, 255, 1)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                px: { xs: 0, sm: 5, md: 5 },
              }}
            >
              {/* {location.pathname === "/" ? ( */}
              <Box
                component={"img"}
                src="/images/Fault Logo.svg"
                sx={headerStyle.logoImg}
                onClick={() => navigate("/")}
              />
              {/* ) : (
                <Box
                  component={"img"}
                  src="/images/logo 2.svg"
                  sx={{ ...headerStyle.logoImg, height: "2.5rem" }}
                  onClick={() => navigate("/")}
                />
              )} */}

              {!Auth && (
                <Box sx={{ display: "flex" }}>
                  <Button
                    sx={headerStyle.signup}
                    onClick={() => setOpenDialog(true)}
                  >
                    <p> Signup</p>
                  </Button>
                  <Button
                    variant="contained"
                    sx={headerStyle.signIn}
                    onClick={() => {
                      setIsLogin(true);
                      setOpenDialog(true);
                    }}
                  >
                    <p> Login </p>
                  </Button>
                </Box>
              )}

              {Auth && (
                <Box
                  sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      mr: 2,
                      display: { xs: "none", sm: "flex", md: "flex" },
                    }}
                  >
                    <p> {nEmail ? nEmail : email}</p>
                  </Typography>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={email?.charAt(0)}
                        src={nPicture ? nPicture : picture}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => setAnchorElUser(null)}
                  >
                    <MenuItem onClick={() => setOpenLogout(true)}>
                      <Typography
                        textAlign="center"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          fontWeight: 600,
                        }}
                      >
                        <LogoutIcon />
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Authentication
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        setAuth={setAuth}
        userId={userId}
        setUserId={setUserId}
      />

      <Dialog
        open={openLogout}
        onClose={() => {
          setOpenLogout(false);
        }}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "1.2rem",
            },
          },
        }}
      >
        <Box
          sx={{
            bgColor: ColorsHelper.whiteClr,
            border: `1px solid ${ColorsHelper.cardBorder}`,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: 2,
            py: "20px",
          }}
        >
          <Typography
            sx={{
              color: ColorsHelper.blackClr,
              fontWeight: 600,
              fontSize: "21px",
              lineHeight: "1.2",
              p: 1.3,
              textAlign: "center",
            }}
          >
            Are you sure want to logout?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: { xs: 0, sm: 4, md: 4 },
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                bgcolor: "white !important",
                color: "black !important",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                border: `1px solid rgba(19, 19, 19, 0.2) !important`,
                width: "8rem",
                boxShadow: "none !important",
                py: 1,
                borderRadius: "0.7rem",
              }}
              onClick={() => {
                setOpenLogout(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "rgba(240, 195, 192, 1) !important",
                color: "rgba(130, 29, 7, 1) !important",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                py: 1,
                borderRadius: "0.7rem",
                width: "8rem",
                boxShadow: "none !important",
              }}
              onClick={handleCloseUserMenu}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Header;
