import zIndex from "@mui/material/styles/zIndex";
import ColorsHelper from "../../../../Helpers/Colors";

const Cstyle = {
  createBox: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 5,
    width: "100%",
  },
  createBtn: {
    bgcolor: ColorsHelper?.primary + "!important",
    color: ColorsHelper?.blackClr,
    fontSize: "17px",
    fontWeight: 600,
    px: 2,
    py: 1,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: `1px solid transparent`,
    width: { xs: "100%", sm: "auto" },
  },
  boardMainBox: {
    border: `1px solid ${ColorsHelper.cardBorder}`,
    borderRadius: 2,
    height: "120px",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover .board-content": {
      transform: "translate(-30%, -30%)",
      position: "absolute",
      fontSize: "10px",
    },
    "&:hover .hover-content": {
      display: "flex",
      opacity: 1,
    },
    "&:hover .color-box": {
      opacity: 0,
    },
  },
  boardTitleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: 2,
    transition: "transform 0.3s ease-in-out",
  },
  boardTitle: {
    fontWeight: 600,
    fontSize: "22px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "10ch",
    maxWidth: "100%",
  },
  cardColorBox: {},
  hoverBox: {
    display: "none",
    opacity: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.3s ease-in-out",
  },
  noBoardBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  dialog: {
    // "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "380px",
      borderRadius: "1.2rem",
    },
    // },
  },
  dialogMainBox: {
    bgColor: ColorsHelper.whiteClr,
    border: `1px solid ${ColorsHelper.cardBorder}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    p: 2,
  },
  deleteText: {
    color: ColorsHelper.blackClr,
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "1.2",
    p: 1.3,
    textAlign: "center",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    px: { xs: 0, sm: 4, md: 4 },
    mt: 2,
  },
  cancelBtn: {
    bgcolor: "white !important",
    color: "black !important",
    textTransform: "capitalize",
    fontSize: "1.1rem",
    py: 1,
    borderRadius: "0.7rem",
    borderColor: "black !important",
    width: "8rem",
  },
  deleteBtn: {
    bgcolor: "rgba(240, 195, 192, 1) !important",
    color: "rgba(130, 29, 7, 1) !important",
    textTransform: "capitalize",
    fontSize: "1.1rem",
    py: 1,
    borderRadius: "0.7rem",
    width: "8rem",
    borderColor: "rgba(130, 29, 7, 1) !important",
  },
};

export default Cstyle;
