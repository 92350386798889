// MUI Import
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

// Third Party Import
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const Collection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const storedUser: any = localStorage.getItem("ADMIN_FV");
    if (storedUser) {
      navigate("/card");
    } else {
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 5,
        mt: 5,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: { xs: "1.8rem", sm: "2.5rem", md: "2.5rem" },
            fontWeight: 700,
          }}
        >
          <p> How it works? </p>
        </Typography>
        <Typography
          sx={{
            color: "#353636",
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
            fontWeight: 500,
            maxWidth: "480px",
          }}
        >
          <p>
            See how FeedVault makes feedback collection simple and effective.{" "}
          </p>
        </Typography>

        <Grid
          container
          // spacing={2}
          justifyContent="center"
          sx={{ mt: { xs: 4, sm: 0, md: 0 } }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <List>
              {[
                "<b>Collect Feedback:</b> <br/>Use in-app prompts, surveys, and forms to gather user insights.",
                "<b>Prioritize Features:</b> <br/> Smart algorithms rank feedback by user impact.",
                "<b>Act Quickly:</b> <br/> Turn feedback into tasks, assign them, and track progress.",
              ].map((text, index) => (
                <ListItem key={index} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <p
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                        style={{
                          color: "rgba(19, 36, 9, 1)",
                          fontWeight: 500,
                          fontSize: "0.95rem",
                        }}
                      />
                    }
                  />
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                bgcolor: "#85EE44 !important",
                color: "black",
                fontWeight: 700,
                borderRadius: "10px",
                textTransform: "none",
                boxShadow: "none !important",
              }}
              onClick={handleClick}
            >
              <p> Start Collecting Feedbacks for Free </p>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Box
              component="img"
              // src="/images/Laptop.svg"
              src="/images/lp3.png"
              sx={{
                width: "100%",
                height: "100%",
                // border: "1px solid red",
                // top: "-30px",
                // position: "relative",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Collection;
