// React Import
import { useState } from "react";

// MUI Import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StarIcon from "@mui/icons-material/Star";

const FAQ = () => {
  const [expanded, setExpanded] = useState();

  const data = [
    {
      title: "It is free to use?",
      desc: "Personalize boards with your company name and logo.",
      image: "/images/accordion 1.svg",
    },
    {
      title: "How to setup feedback module?",
      desc: "Customize your boards with your company’s branding and colors",
      image: "/images/accordion 2.svg",
    },
    {
      title: "How can I change the board color?",
      desc: "Generate a public link and embed code for easy sharing and integration on your website.",
      image: "/images/accordion 3.svg",
    },
    {
      title: "Can I set my company name?",
      desc: "Generate a public link and embed code for easy sharing and integration on your website.",
      image: "/images/accordion 3.svg",
    },
    {
      title: "How to share the embed code?",
      desc: "Generate a public link and embed code for easy sharing and integration on your website.",
      image: "/images/accordion 3.svg",
    },
    {
      title: "Can I customize the board?",
      desc: "Generate a public link and embed code for easy sharing and integration on your website.",
      image: "/images/accordion 3.svg",
    },
  ];

  const handleAccordionChange =
    (index: any) => (event: any, isExpanded: any) => {
      setExpanded(isExpanded ? index : null);
    };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 13,
        bgcolor: "rgba(249, 249, 249, 1)",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: { xs: "1.8rem", sm: "3rem", md: "3rem" },
            fontWeight: 700,
          }}
        >
          <p> Frequently Asked Questions </p>
        </Typography>
        <Box sx={{ mt: 6 }}>
          {data.map((item, index) => (
            <Accordion
              sx={{
                bgcolor: "transparent",
                boxShadow: "none",
                "&:before": { opacity: "1 !important" },
                "&.Mui-expanded": {
                  mt: "0 !important",
                },
              }}
              key={index}
              expanded={expanded === index}
              onChange={handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                sx={{
                  color: "#132409",
                  fontFamily: "Roobert",
                  fontWeight: 600,
                  py: 2,
                  fontSize: "1.1rem",
                }}
              >
                <p>{item.title}</p>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex" }}>
                <Typography sx={{ display: "flex", textAlign: "start" }}>
                  <p>{item.desc} </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 12,
          }}
        >
          <Box
            component={"img"}
            src="/images/quote.svg"
            sx={{
              color: "rgba(82, 165, 32, 1)",
              mb: 6,
            }}
          />
          <Typography
            sx={{
              color: "rgba(53, 54, 54, 1)",
              fontSize: "1.13rem",
              fontWeight: 400,
              maxWidth: "600px",
              mb: 3,
            }}
          >
            <p>
              {" "}
              Customizing our boards with our brand colors and logo has
              strengthened our connection with customers.
            </p>
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            {[...Array(5)].map((_, index) => (
              <Grid item key={index}>
                <StarIcon sx={{ color: "#C8A800", width: "1.3rem" }} />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Avatar
              src="/images/tm2.png"
              alt="Steve Pole"
              sx={{ width: 56, height: 56, mr: 2 }}
            />
            <Box sx={{ textAlign: "start" }}>
              <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                <p> Vince Potter </p>
              </Typography>
              <Typography
                sx={{ fontSize: "0.875rem", color: "rgba(53, 54, 54, 1)" }}
              >
                <p> Potter Tenz Ltd</p>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
