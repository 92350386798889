import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../index.css";

// Components Imports
import Header from "../Pages/Admin/CreateBoard/Header/Header";
import PageOne from "../Pages/Admin/CreateBoard/Board";
import Card from "../Pages/Admin/CreateBoard/Card";
import Suggestions from "../Pages/Admin/CreateBoard/Suggestion";
import Dashboard from "../Pages/User/Dashboard";
import Home from "../Pages/Admin/CreateBoard/Home";
import Comments from "../Pages/User/Comment";
import Error404 from "../Pages/404Error";

const LayoutWrapper = () => {
  // Hooks
  const location = useLocation();

  // Define the routes where the Header should be hidden
  const hideHeaderRoutes = ["/home", "/suggestion", "/card"];
  const shouldHideHeader = hideHeaderRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      {/* Conditionally render the Header */}
      {(shouldHideHeader ||
        `${window.location.origin}/` === window.location.href) && <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Dashboard />} />
        <Route path="/post/:id" element={<Comments />} />

        <Route path="/home" element={<PageOne />} />
        <Route path="/card" element={<Card />} />
        <Route path="/suggestion/:index" element={<Suggestions />} />

        <Route path="*" element={<Navigate to="/" />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default LayoutWrapper;
