import { BrowserRouter as Router } from "react-router-dom";

import LayoutWrapper from "./Layout";

const App = () => {
  const user = localStorage.getItem("ADMIN_FV");
  const path = window.location.pathname;

  const hideHeaderPaths = ["/user", "/post"];
  const shouldHideHeader = hideHeaderPaths.some((p) => path.startsWith(p));

  return (
    <Router>
      <LayoutWrapper />
    </Router>
  );
};

export default App;
