// MUI Import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";

// Third Party Import
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const Hero = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const storedUser: any = localStorage.getItem("ADMIN_FV");
    if (storedUser) {
      navigate("/card");
    } else {
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "rgba(246, 255, 241, 1)",
        py: 5,
        overflow: "hidden",
      }}
    >
      <Container
        sx={{
          mt: { xs: 0, sm: 5, md: 5 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "rgba(19, 36, 9, 1)",
              fontSize: { xs: "1.8rem", sm: "3rem", md: "3.5rem" },
              fontWeight: 700,
              fontFamily: "Roobert",
            }}
          >
            <span
              style={{
                color: "rgba(82, 165, 32, 1)",
                fontWeight: "bold",
                fontFamily: "Roobert",
              }}
            >
              Ship Features
            </span>{" "}
            That <br />
            Truly Matter to Your Users
          </Typography>

          <Typography
            sx={{
              color: "rgba(53, 54, 54, 1)",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
              mt: 1,
              fontWeight: 500,
              maxWidth: "500px",
            }}
          >
            <p>
              {" "}
              Gather Feedback, Prioritize What Matters, and Build Products Users
              Crave
            </p>
          </Typography>

          <Box
            component={"img"}
            src="/images/Blur.svg"
            sx={{
              position: "absolute",
              opacity: 0.26,
              width: { xs: "18rem", sm: "33rem", md: "33rem" },
            }}
          />
        </Box>

        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)", mr: 1 }} />
              <Typography
                sx={{
                  color: "rgba(19, 36, 9, 1)",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                <p> Free Forever! </p>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIcon sx={{ color: "rgba(82, 165, 32, 1)", mr: 1 }} />
              <Typography
                sx={{
                  color: "rgba(19, 36, 9, 1)",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                <p> Quick & Easy Setup</p>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Button
          variant="contained"
          size="large"
          sx={{
            mt: 3,
            bgcolor: "#85EE44 !important",
            color: "black",
            fontWeight: 700,
            borderRadius: "10px",
            textTransform: "none",
            boxShadow: "none !important",
            px: { xs: 3, sm: 4, md: 4 },
            py: 1,
            fontSize: "1.05rem",
          }}
          onClick={handleClick}
        >
          <p>Start Collecting Feedbacks for Free</p>
        </Button>
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: "1rem",
            fontWeight: 500,
            mt: "0.2rem",
            mb: 4,
          }}
        >
          <p>Its free forever!</p>
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", my: 4, gap: 1 }}>
          <AvatarGroup max={4}>
            <Avatar
              alt="Remy Sharp"
              src="/images/avatar.jpg"
              sx={{ width: "2rem", height: "2rem" }}
            />
            <Avatar
              alt="Travis Howard"
              src="/images/user2.png"
              sx={{ width: "2rem", height: "2rem" }}
            />
            <Avatar
              alt="Cindy Baker"
              src="/images/user3.png"
              sx={{ width: "2rem", height: "2rem" }}
            />
            <Avatar
              alt="Agnes Walker"
              src="/images/user4.png"
              sx={{ width: "2rem", height: "2rem" }}
            />
          </AvatarGroup>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              display: "flex",
              gap: 0.5,
            }}
          >
            <b>580+</b> <p>Founders Relaxed</p>
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "970px",
            width: "100%",
          }}
        >
          <Box
            component="img"
            // src="/images/hero laptop.svg"
            src="/images/image 1.png"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
