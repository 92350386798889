import Box from "@mui/material/Box";

const Error404 = () => {
  return (
    <Box className="content-center">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component={"img"}
          alt="error-illustration"
          src="/images/404.svg"
          sx={{ height: "33rem", width: { xs: "22rem" } }}
        />
      </Box>
    </Box>
  );
};

export default Error404;
