// React Import
import { useState } from "react";

// MUI Import
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";

// Third Party Import
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const Branding = () => {
  const [expanded, setExpanded] = useState(0);

  const navigate = useNavigate();

  const handleClick = () => {
    const storedUser: any = localStorage.getItem("ADMIN_FV");
    if (storedUser) {
      navigate("/card");
    } else {
      enqueueSnackbar("Please log in first", {
        variant: "error",
      });
    }
  };

  const data = [
    {
      title: "Company Identity",
      desc: "Personalized with you company name for seamless integration.",
      image: "/images/accordion 1.svg",
    },
    {
      title: "Color Themes",
      desc: "Customize boards with your brand colors and themes.",
      image: "/images/newAccordinon 2.png",
    },
    {
      title: "Public Visibility",
      desc: "Choose to keep boards private or make them public.",
      image: "/images/accordion 3.svg",
    },
  ];

  const handleAccordionChange =
    (index: any) => (event: any, isExpanded: any) => {
      setExpanded(isExpanded ? index : 0);
    };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 2,
        my: 5,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(19, 36, 9, 1)",
            fontSize: { xs: "1.8rem", sm: "3rem", md: "3rem" },
            fontWeight: 700,
          }}
        >
          <p> Branding Customization </p>
        </Typography>
        <Typography
          sx={{
            color: "rgba(53, 54, 54, 1)",
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
            mb: 5,
            fontWeight: 500,
            maxWidth: "500px",
          }}
        >
          <p>
            Tailor FeedVault to match your brand with our customization options.
          </p>
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={7}>
            <Box
              component="img"
              src={data[expanded].image}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            {data.map((item, index) => (
              <Accordion
                sx={{
                  bgcolor: "transparent",
                  boxShadow: "none",
                  "&:before": { opacity: "1 !important" },
                  "&.Mui-expanded": {
                    my: "0 !important",
                  },
                }}
                key={index}
                expanded={expanded === index}
                onChange={handleAccordionChange(index)}
              >
                <AccordionSummary
                  expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={{
                    color:
                      expanded === index
                        ? "rgba(82, 165, 32, 1)"
                        : "rgba(19, 36, 9, 1)",
                    fontFamily: "Roobert",
                    fontWeight: 600,
                  }}
                >
                  <p>
                    {" "}
                    {index + 1}. {item.title}{" "}
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      textAlign: "start",
                    }}
                  >
                    <p> {item.desc} </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
            <Button
              variant="contained"
              size="large"
              sx={{
                mt: 7,
                bgcolor: "rgba(133, 238, 68, 1) !important",
                color: "black",
                fontWeight: 700,
                borderRadius: "10px",
                textTransform: "none",
                boxShadow: "none !important",
              }}
              onClick={handleClick}
            >
              <p> Try FeedVault for Free </p>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Branding;
